/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { omit } from 'lodash';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import type { ChangeEvent, SetStateAction } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import {
  getAllCountriesWithRegionOrStateStaticProps,
  getLocation,
  getLocationPath,
} from 'src/actions/countryActions';
import {
  addHumanRecord,
  getHumanById,
  getHumanHistory,
} from 'src/actions/editActions';
import { suggestDeletionEmail } from 'src/actions/mail';
import {
  searchByFilters,
  searchForAllCountriesStaticProps,
} from 'src/actions/searchActions';
import InfoIcon from 'src/assets/InfoIcon';
import BackButton from 'src/components/button/back-button';
import CompletationStatus from 'src/components/completion-status/completion-status';
import OutsideClickHandler from 'src/components/core/outsideclickhandler';

import EditHumanForm from 'src/components/edit-human-form/EditHumanForm';

import { NavFooter } from 'src/components/nav/navFooter.component';
import NotLoggedInBanner from 'src/components/not-logged-in-banner.tsx/not-logged-in-banner';
import { PrimaryButton } from 'src/components/primary-button/primary-button';
import { RecordStillProcessing } from 'src/components/record-still-processing/record-still-processing';
import { SecondaryButton } from 'src/components/secondary-button/secondary-button';
import { showDevPages } from 'src/config';
import { monthOptions } from 'src/filters/day-month-year-filter';
import { useKeyDownHandler } from 'src/hooks/useKeyDownHandler';
import { editActions } from 'src/reducers/editFlowReducer';

import { actions as searchActions } from 'src/reducers/searchReducer';

import { userActions } from 'src/reducers/userReducers';
import type { RootState } from 'src/store';
import type { OptionTypes } from 'src/types/actionSheetTypes';
import type {
  DateErrorOptions,
  FemaleCompletionObjectTypes,
  HumanObjectType,
  HumanTypes,
  ListRegionStatesTypes,
  LocationsTypes,
  MaleCompletionObjectTypes,
  PathTypes,
} from 'src/types/edit-create-types';
import type {
  HumanArrayFromServer,
  HumanPersonTypes,
  PeopleTypes,
} from 'src/types/edit-history-types';
import type { AddHumanRecord } from 'src/types/human';
import type { DataWorldChartTypes } from 'src/types/types';
import type { EType, NewHumanTypes } from 'src/types/user';
import { calculateCompletionIndex } from 'src/utils/calculateCompletionIndex';
import { useCheckDateFormatFunctionality } from 'src/utils/checkDateFormatCreationEdit';
import { checkParams } from 'src/utils/checkParams';
import { deleteCookie, getCookie, setCookie } from 'src/utils/cookies';
import { createHumanArray } from 'src/utils/createHumanArray';
import { displayToast } from 'src/utils/displayToast';
import { formatName } from 'src/utils/format';
import { getAllChangesFromPersonHistory } from 'src/utils/getAllChangesFromPersonHistory';
import { isBirthDateBeforeDeathDate } from 'src/utils/isBirthDateBeforeDeathDate';
import { useTypingDetector } from 'src/utils/isTypingDetector';
import { capitalizeString } from 'src/utils/string';
import { toTitleCase } from 'src/utils/toTitleCase';
import { trackCustomEvent } from 'src/utils/trackCustomEvents';
import ErrorIcon from '../assets/ErrorIcon';
import SuccessIcon from '../assets/SuccessIcon';
import { Spinner } from '../components/core/spinner.component';
import StandardSEOWrapper from '../components/layout/standardSEOWrapper.component';
import LoginModal from '../components/login-modal/login-modal';
import { parseMDY } from '../utils/parseMDY';

const SHOW_PAGE = showDevPages;

interface EditRecordProps {
  countries: DataWorldChartTypes[];
  human: HumanTypes[];
  ip: string;
  countryIso3FromProps: string;
  allCountriesWithRegions: LocationsTypes[];
}

export default function EditRecord({
  countries,
  human: humanFromProps,
  ip,
  countryIso3FromProps,
  allCountriesWithRegions,
}: EditRecordProps) {
  const router = useRouter();
  const [humanFromState, setHumanFromClient] = useState<HumanTypes[] | null>(
    null
  );
  const [isInferred, setIsInferred] = useState<boolean | string>(false);

  const [searchTermCountry, setSearchTermCountry] = useState('');
  const [isCheckingUserCookieOption, setIsCheckingUserCookieOption] =
    useState<boolean>(true);
  const [countriesDropdown, setCountriesDropdown] = useState(false);
  const [isMaleSex, setIsMaleSex] = useState<boolean>(false);
  const [isFemaleSex, setIsFemaleSex] = useState<boolean>(false);
  const [isAnySexSelected, setIsAnySexSelected] = useState<boolean>(false);
  const [isValidRecord, setIsValidRecord] = useState<boolean>(false);
  const [isUniqueRecord, setIsUniqueRecord] = useState<boolean>(false);

  const { isLoggedIn, editCreateOption, userName, userId } = useSelector(
    (state: RootState) => state.user
  );

  const [initalHuman, setInitialHuman] = useState<HumanObjectType>({});
  const [newHuman, setNewHuman] = useState<HumanObjectType>({});

  const [firstName1, setFirstName1] = useState('');
  const [firstName1InputColor, setFirstName1InputColor] =
    useState('create-human-input');

  const [maidenName, setMaidenName] = useState('');
  const [maidenNameInputColor, setMaidenNameInputColor] =
    useState('create-human-input');

  const [firstName2, setFirstName2] = useState('');
  const [firstName2InputColor, setFirstName2InputColor] =
    useState('create-human-input');

  const [lastName, setLastName] = useState('');
  const [lastName1InputColor, setLastName1InputColor] =
    useState('create-human-input');

  const [birthDay, setBirthDay] = useState('');

  const [birthMonth, setBirthMonth] = useState('');

  const [birthYear, setBirthYear] = useState('');

  const [deathDay, setDeathDay] = useState('');

  const [deathMonth, setDeathMonth] = useState('');

  const [deathYear, setDeathYear] = useState('');

  const [birthCountry, setBirthCountry] = useState('');

  const [country, setCountry] = useState('');

  const [searchTermBirthCountry, setSearchTermBirthCountry] = useState('');

  const [humanRecord, setHumanRecord] = useState<AddHumanRecord | null>(null);
  const [humanFromURL, setHumanFromURL] = useState<AddHumanRecord | null>(null);

  const [selectedBirthApproxValue, setSelectedBirthApproxValue] = useState(0);
  const [showBirthApproxDropdown, setShowBirthApproxDropdown] = useState(false);
  const [birthCountriesDropdown, setBirthCountriesDropdown] = useState(false);
  const [stateRegionDropdown, setStateRegionDropdown] = useState(false);
  const [stateRegionDeathDropdown, setStateRegionDeathDropdown] =
    useState(false);
  const [pathDropdown, setPathDropdown] = useState(false);
  const [pathDeathDropdown, setPathDeathDropdown] = useState(false);
  const [deathCountriesDropdown, setDeathCountriesDropdown] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const handleShowDeletionModal = () => {
    setShowDeletionModal(!showDeletionModal);
  };

  // Refs for inputs
  const firstName1Ref = useRef<HTMLInputElement>(null);
  const isTypingInFirstName1 = useTypingDetector(firstName1Ref?.current);
  const firstName2Ref = useRef<HTMLInputElement>(null);
  const isTypingInFirstName2 = useTypingDetector(firstName2Ref?.current);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const isTypingInLastName = useTypingDetector(lastNameRef?.current);
  const maidenNameRef = useRef<HTMLInputElement>(null);
  const isTypingInMaidenName = useTypingDetector(maidenNameRef?.current);
  const birthLocationRef = useRef<HTMLInputElement>(null);
  const isTypingInBirthLodation = useTypingDetector(birthLocationRef?.current);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const isTypingInBirthDate = useTypingDetector(birthDateRef?.current, 1500);
  const deathDateRef = useRef<HTMLInputElement>(null);
  const isTypingInDeathDate = useTypingDetector(deathDateRef?.current, 1500);
  const deathLocationRef = useRef<HTMLInputElement>(null);
  const isTypingInDeathLocation = useTypingDetector(deathLocationRef?.current);

  const handleShowBirthApproxDropdown = () => {
    setShowBirthApproxDropdown(!showBirthApproxDropdown);
  };

  const selectBirthApproxValue = (e: EType) => {
    setSelectedBirthApproxValue(parseInt(e.target.innerText));
    handleShowBirthApproxDropdown();
  };

  const [showTable, setShowTable] = useState(false);
  const [numberOfSimilarRecords, setNumberOfSimilarRecords] = useState(0);
  const [arrayFromServer, setArrayFromServer] = useState([]);

  const resolveHumanLocation = (
    city: string | undefined,
    region: string | undefined,
    country: string | undefined
  ) => {
    if (!city && !region && !country) {
      return '';
    }
    if (!city && !region && country) {
      return country === `I don't know` ? '' : country;
    }
    if (!city && region && !country) {
      return region === `I don't know` ? '' : region;
    }
    if (city && !region && !country) {
      return city === `I don't know` ? '' : city;
    }
    if (
      city &&
      city !== `I don't know` &&
      region &&
      region !== `I don't know` &&
      !country
    ) {
      return city + ', ' + region;
    }
    if (
      !city &&
      region &&
      region !== `I don't know` &&
      country &&
      country !== `I don't know`
    ) {
      return region + ', ' + country;
    }
    if (
      city &&
      city !== `I don't know` &&
      !region &&
      country &&
      country !== `I don't know`
    ) {
      return city + ', ' + country;
    }
  };

  const [showCalendarBirth, setShowCalendarBirth] = useState(false);
  const [addBirthDateInputColor, setAddBirthDateInputColor] =
    useState('create-human-input');
  const [errorBirthDateInputColor, setErrorBirthDateInputColor] =
    useState<DateErrorOptions>('create-human-input');
  const [errorDeathDateInputColor, setErrorDeathDateInputColor] =
    useState<DateErrorOptions>('create-human-input');

  const handleCloseCalendarBirth = () => {
    setShowCalendarBirth(false);
  };

  const [valueToRecordForDateBirth, setValueToRecordForDateBirth] =
    useState('');

  const [valueToRecordForDeath, setValueToRecordForDeath] = useState('');

  const [
    isBirthDateEmptyButItHadAValueBefore,
    setIsBirthDateEmptyButItHadAValueBefore,
  ] = useState(false);

  const [
    isDeathDateEmptyButItHadAValueBefore,
    setIsDeathDateEmptyButItHadAValueBefore,
  ] = useState(false);

  // Manage all the date error toasts in this useEffect
  useEffect(() => {
    if (
      errorBirthDateInputColor === 'create-human-input-error-day-wrong' ||
      errorDeathDateInputColor === 'create-human-input-error-day-wrong'
    ) {
      displayToast(
        'The day you entered is not valid for the month and year you entered. Please try again.',
        'error',
        <ErrorIcon />,
        'day-wrong'
      );
    }
    if (
      errorBirthDateInputColor === 'create-human-input-error-month-wrong' ||
      errorDeathDateInputColor === 'create-human-input-error-month-wrong'
    ) {
      displayToast(
        'The month you entered is not valid. Please try again.',
        'error',
        <ErrorIcon />,
        'month-wrong'
      );
    }
    if (
      errorBirthDateInputColor === 'create-human-input-error-year' ||
      errorDeathDateInputColor === 'create-human-input-error-year'
    ) {
      displayToast(
        `Year cannot be in the future`,
        'error',
        <ErrorIcon />,
        'year-in-future'
      );
    }
    if (
      errorBirthDateInputColor === 'create-human-input-error' ||
      errorDeathDateInputColor === 'create-human-input-error'
    ) {
      displayToast(
        'Follow MM/DD/YYYY, MM/YYYY or YYYY format',
        'error',
        <ErrorIcon />,
        'follow-mm-dd-yyyy'
      );
    }
  }, [errorBirthDateInputColor, errorDeathDateInputColor]);

  const checkBirthDateFormat = useCheckDateFormatFunctionality(
    isTypingInBirthDate,
    valueToRecordForDateBirth,
    setErrorBirthDateInputColor,
    setBirthDay,
    setBirthMonth,
    setBirthYear
  );

  useEffect(() => {
    checkBirthDateFormat();
  }, [checkBirthDateFormat]);

  const checkDeathDateFormat = useCheckDateFormatFunctionality(
    isTypingInDeathDate,
    valueToRecordForDeath,
    setErrorDeathDateInputColor,
    setDeathDay,
    setDeathMonth,
    setDeathYear
  );

  useEffect(() => {
    checkDeathDateFormat();
  }, [checkDeathDateFormat]);

  useEffect(() => {
    if (humanFromProps?.length === 0) {
      router?.push({
        pathname: '/404',
      });
    } else {
      resetFileds();
      setHumanFromClient(humanFromProps);
    }
  }, [humanFromProps]);

  const [birthCity, setBirthCity] = useState('');

  const [searchTermBirthCity, setSearchTermBirthCity] = useState('');

  const [isCreating, setIsCreating] = useState(false);
  const [birthRegion, setBirthRegion] = useState('');

  const [searchTermBirthRegion, setSearchTermBirthRegion] = useState('');

  const [birthRegionPath, setBirthRegionPath] = useState('');

  const [deathCity, setDeathCity] = useState('');

  const [searchTermDeathCity, setSearchTermDeathCity] = useState('');

  const [deathRegion, setDeathRegion] = useState('');

  const [searchTermDeathRegion, setSearchTermDeathRegion] = useState('');

  const handleDeathRegion = (region: string) => {
    setDeathRegion(region);
  };

  const handleIsInferred = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInferred(e?.target?.value === '1');
  };

  const [deathCountry, setDeathCountry] = useState('');

  const [searchTermDeathCountry, setSearchTermDeathCountry] = useState('');

  const [deathRegionPath, setDeathRegionPath] = useState('');
  const [continueAndReview, setContinueAndReview] = useState(false);
  const [isLoadingSimilarRecords, setIsLoadingSimilarRecords] = useState(false);
  const [isLoadingGetLocation, setIsLoadingGetLocation] = useState(false);
  const [isLoadingGetLocationPath, setIsLoadingGetLocationPath] =
    useState(false);
  const [isLoadingGetLocationPathDeath, setIsLoadingGetLocationPathDeath] =
    useState(false);

  const handleContinueAndReview = () => {
    reviewRecord();
    setContinueAndReview((prev) => !prev);
  };
  const birthDate = useMemo(() => {
    return {
      day: birthDay,
      month:
        monthOptions?.find((month) => month.value == birthMonth)?.label || '',
      year: birthYear,
    };
  }, [birthDay, birthMonth, birthYear]);

  const deathDate = useMemo(() => {
    return {
      day: deathDay,
      month:
        monthOptions?.find((month) => month.value == deathMonth)?.label || '',
      year: deathYear,
    };
  }, [deathDay, deathMonth, deathYear]);

  const isConfirmAndContinueButtonEnabled = useMemo(() => {
    return (
      (isMaleSex || isFemaleSex) &&
      firstName1 &&
      lastName &&
      country &&
      numberOfSimilarRecords === 0 &&
      isBirthDateBeforeDeathDate(birthDate, deathDate)
    );
  }, [
    isMaleSex,
    isFemaleSex,
    firstName1,
    lastName,
    country,
    numberOfSimilarRecords,
    birthDate,
    deathDate,
  ]);

  const searchForSimilarRecords: () => void = debounce(() => {
    const countryIso3 =
      countries?.find(
        (countryProps) =>
          countryProps?.iso_3?.toLowerCase() === country?.toLowerCase()
      )?.iso_3 ||
      countries?.find(
        (countryProps) =>
          countryProps?.name?.toLowerCase() === country?.toLowerCase()
      )?.iso_3;

    const params = {
      first_name2: '',
      birth_day: '',
      birth_month: '',
      birth_year: '',
      birth_country: '',
      birth_region: '',
      birth_city: '',
      death_day: '',
      death_month: '',
      death_year: '',
      death_country: '',
      death_region: '',
      death_city: '',
      sex: '',
      alive: 'both',
      maiden_name: '',
      birth_margin: '',
    };

    for (const value in params) {
      if (firstName2?.length > 0) {
        params.first_name2 = firstName2.trim();
      }

      if (maidenName?.length > 0) {
        params.maiden_name = maidenName.trim();
      }

      if (birthDay?.length > 0) {
        params.birth_day = birthDay;
      }

      if (birthMonth?.length > 0) {
        params.birth_month = birthMonth;
      }

      if (birthYear?.length > 0) {
        params.birth_year = birthYear;
      }

      if (deathDay?.length > 0) {
        params.death_day = deathDay;
      }

      if (deathMonth?.length > 0) {
        params.death_month = deathMonth;
      }

      if (deathYear?.length > 0) {
        params.death_year = deathYear;
      }

      if (selectedBirthApproxValue === 1) {
        params.birth_margin = '1';
      }

      if (birthCountry?.length > 0) {
        const birthCountryPlace = allCountriesWithRegions?.find(
          (locationCountry) =>
            locationCountry?.place?.toLowerCase() ===
            birthCountry?.toLowerCase()
        )?.id;
        params.birth_country = birthCountryPlace?.toString() || '';
      }

      if (birthRegion?.length > 0) {
        const birthRegionPlace = listOfRegionsStates?.find(
          (locationRegionState) =>
            locationRegionState?.place?.toLowerCase() ===
            birthRegion?.toLowerCase()
        )?.id;
        params.birth_region = birthRegionPlace?.toString() || '';
      }

      if (birthCity?.length > 0) {
        const birthCityPlace = pathsForLocation?.find(
          (locationPath) =>
            locationPath?.place?.toLowerCase() === birthCity?.toLowerCase()
        )?.id;
        params.birth_city = birthCityPlace?.toString() || '';
      }

      if (deathCountry?.length > 0) {
        const deathCountryPlace = allCountriesWithRegions?.find(
          (locationCountry) =>
            locationCountry?.place?.toLowerCase() ===
            deathCountry?.toLowerCase()
        )?.id;
        params.death_country = deathCountryPlace?.toString() || '';
      }

      if (deathRegion?.length > 0) {
        const deathRegionPlace = listOfRegionsStatesDeath?.find(
          (locationRegionState) =>
            locationRegionState?.place?.toLowerCase() ===
            deathRegion?.toLowerCase()
        )?.id;
        params.death_region = deathRegionPlace?.toString() || '';
      }

      if (deathCity?.length > 0) {
        const deathCityPlace = pathsForLocationDeath?.find(
          (locationPath) =>
            locationPath?.place?.toLowerCase() === deathCity?.toLowerCase()
        )?.id;
        params.death_city = deathCityPlace?.toString() || '';
      }

      if (isMaleSex && isAnySexSelected) {
        params.sex = 'male';
      }

      if (isFemaleSex && isAnySexSelected) {
        params.sex = 'female';
      }

      if (!isAnySexSelected) {
        params.sex = 'both';
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ((params[value] as string)?.length === 0 || !params[value]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete params[value];
      }
    }

    if (isEqual(initalHuman, newHuman) === false) {
      if (checkParams(params)) {
        if (
          params?.death_month !== 'Invalid date' &&
          params?.birth_month !== 'Invalid date'
        ) {
          const domainName = countries?.find((countryFromProps) => {
            return (
              countryFromProps?.iso_3?.toLowerCase() ===
              countryIso3?.toLowerCase()
            );
          })?.api_public_domain_name as string;
          if (isEmpty(params)) {
            searchByFilters(
              firstName1,
              lastName,
              countryIso3,
              '1',
              '100',
              undefined,
              domainName
            )
              .then((res) => {
                if (res) {
                  const data = res?.data.filter(
                    (item: PeopleTypes) =>
                      item?.id !=
                      String(router?.query?.human_slug)?.split('-')?.[0]
                  );
                  const totalMatches = data?.length;

                  setArrayFromServer(data);

                  if (totalMatches < 25 && totalMatches !== 0) {
                    setShowTable(true);
                  }
                  if (totalMatches === 0 || totalMatches > 25) {
                    setShowTable(false);
                  }
                  if (totalMatches) {
                    setIsUniqueRecord(false);
                  } else {
                    setIsUniqueRecord(true);
                  }

                  setNumberOfSimilarRecords(totalMatches || 0);
                } else {
                  setArrayFromServer([]);
                  setShowTable(false);
                  setNumberOfSimilarRecords(0);
                  setIsUniqueRecord(true);
                  setIsValidRecord(true);
                }
                setIsLoadingSimilarRecords(false);
              })
              .catch((error) => {
                setIsLoadingSimilarRecords(false);
                if (error) {
                  setArrayFromServer([]);
                  setShowTable(false);
                  setNumberOfSimilarRecords(0);
                  setIsUniqueRecord(false);
                  setIsValidRecord(false);
                }
              });
          } else {
            searchByFilters(
              firstName1,
              lastName,
              countryIso3,
              '1',
              '100',
              new URLSearchParams(params)?.toString(),
              domainName
            )
              .then((res) => {
                if (res) {
                  const data = res?.data.filter(
                    (item: PeopleTypes) =>
                      item?.id !=
                      String(router?.query?.human_slug)?.split('-')?.[0]
                  );
                  const totalMatches = data?.length;
                  setArrayFromServer(data);
                  if (totalMatches < 25 && totalMatches !== 0) {
                    setShowTable(true);
                  }
                  if (totalMatches === 0 || totalMatches > 25) {
                    setShowTable(false);
                  }

                  setNumberOfSimilarRecords(totalMatches || 0);
                } else {
                  setArrayFromServer([]);
                  setShowTable(false);
                  setNumberOfSimilarRecords(0);
                  setIsUniqueRecord(true);
                  setIsValidRecord(true);
                }
                setIsLoadingSimilarRecords(false);
              })
              .catch((error) => {
                setIsLoadingSimilarRecords(false);
                if (error) {
                  setArrayFromServer([]);
                  setShowTable(false);
                  setNumberOfSimilarRecords(0);
                  setIsUniqueRecord(false);
                  setIsValidRecord(false);
                }
              });
          }
        } else {
          console.log('invalid params');
        }
      }
    } else {
      setArrayFromServer([]);
      setShowTable(false);
      setNumberOfSimilarRecords(0);
      setIsUniqueRecord(false);
      setIsValidRecord(false);
      setIsLoadingSimilarRecords(false);
    }
  }, 400);

  const [completionBarWidth, setCompletionBarWidth] = useState<number>(0);

  const getCompletionBarWidth = () => {
    const maleArrayWithDeath: MaleCompletionObjectTypes = {
      firstName1,
      firstName2,
      lastName,
      sex: isMaleSex ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const femaleArrayWithDeath: FemaleCompletionObjectTypes = {
      firstName1,
      firstName2,
      lastName,
      country,
      sex: isFemaleSex ? 'female' : '',
      maidenName,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const maleArrayWithoutDeath: Omit<
      MaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1,
      firstName2,
      lastName,
      sex: isMaleSex ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
    };

    const femaleArrayWithoutDeath: Omit<
      FemaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1,
      firstName2,
      lastName,
      country,
      sex: isFemaleSex ? 'female' : '',
      birthDay,
      maidenName,
      birthMonth,
      birthYear,
      birthCity,
      birthCountry,
      birthRegion,
    };

    const includesDeathRelatedInformation =
      deathCity?.length > 0 ||
      deathRegion?.length > 0 ||
      deathCountry?.length > 0 ||
      deathMonth?.length > 0 ||
      deathYear?.length > 0 ||
      deathDay?.length > 0;

    let completionObject;

    if (includesDeathRelatedInformation) {
      completionObject = isMaleSex ? maleArrayWithDeath : femaleArrayWithDeath;
    } else {
      completionObject = isMaleSex
        ? maleArrayWithoutDeath
        : femaleArrayWithoutDeath;
    }

    const percentage = calculateCompletionIndex(completionObject);

    setCompletionBarWidth(percentage);
  };

  const zeroCondition =
    firstName1?.length > 0 &&
    lastName?.length > 0 &&
    country?.length > 0 &&
    isAnySexSelected &&
    (isMaleSex || isFemaleSex);

  const checkForValidOrUniqueRecord = useCallback(() => {
    if (
      firstName1InputColor === 'create-human-input' &&
      maidenNameInputColor === 'create-human-input' &&
      firstName2InputColor === 'create-human-input' &&
      lastName1InputColor === 'create-human-input' &&
      zeroCondition
    ) {
      setIsValidRecord(true);
    } else {
      setIsValidRecord(false);
    }
    if (numberOfSimilarRecords === 0 && zeroCondition) {
      setIsUniqueRecord(true);
    } else {
      setIsUniqueRecord(false);
    }
  }, [
    firstName1InputColor,
    firstName2InputColor,
    lastName1InputColor,
    maidenNameInputColor,
    numberOfSimilarRecords,
    zeroCondition,
  ]);

  const [triggerFetchClientSideHuman, setTriggerFetchClientSideHuman] =
    useState(false);

  const [idForClientSideFetch, setIdForClientSideFetch] = useState<string>('');

  const getHumanDataClientSide = useCallback(async () => {
    const countryIso2 = (router?.query?.human_slug as string)?.split('-')?.[1];
    const id = idForClientSideFetch;
    const countryIso3 = countries?.find(
      (country: { iso_2: string }) =>
        country?.iso_2 === countryIso2?.toUpperCase()
    )?.iso_3 as string;
    const countryDomainName = countries?.find(
      (country: { iso_2: string }) =>
        country?.iso_2 === countryIso2?.toUpperCase()
    )?.api_public_domain_name as string;
    const personHistory = await getHumanHistory(
      id,
      countryIso3,
      `${countryDomainName}`
    );

    const human = getAllChangesFromPersonHistory(personHistory?.history);

    const firstName =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'first_name')
        ?.new_value?.toLowerCase() || '';

    const middleName =
      human
        ?.find(
          (person: HumanPersonTypes) => person.field_name === 'first_name2'
        )
        ?.new_value?.toLowerCase() || '';

    const lastName =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'last_name')
        ?.new_value?.toLowerCase() || '';

    const sex =
      human?.find((person: HumanPersonTypes) => person.field_name === 'sex')
        ?.new_value === '1' ||
      human?.find((person: HumanPersonTypes) => person.field_name === 'sex')
        ?.new_value === '10001'
        ? 'male'
        : 'female' || 'both';

    const birthDay =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
        ?.new_value?.split('-')?.[2]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'birth_date'
            )
            ?.new_value?.split('-')?.[2] as string)
        : '' || '';

    const birthMonth =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
        ?.new_value?.split('-')?.[1]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'birth_date'
            )
            ?.new_value?.split('-')?.[1] as string)
        : '' || '';

    const birthYear =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'birth_date')
        ?.new_value?.split('-')?.[0]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'birth_date'
            )
            ?.new_value?.split('-')?.[0] as string)
        : '' || '';

    // Death Data
    const deathDay =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
        ?.new_value?.split('-')?.[2]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'death_date'
            )
            ?.new_value?.split('-')?.[2] as string)
        : '' || '';

    const deathMonth =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
        ?.new_value?.split('-')?.[1]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'death_date'
            )
            ?.new_value?.split('-')?.[1] as string)
        : '' || '';

    const deathYear =
      human
        ?.find((person: HumanPersonTypes) => person.field_name === 'death_date')
        ?.new_value?.split('-')?.[0]
        ?.trim() !== '00'
        ? (human
            ?.find(
              (person: HumanPersonTypes) => person.field_name === 'death_date'
            )
            ?.new_value?.split('-')?.[0] as string)
        : '' || '';

    const searchParams = {
      first_name2: '',
      birth_day: '',
      birth_month: '',
      birth_year: '',
      birth_country: '',
      birth_region: '',
      birth_city: '',
      death_day: '',
      death_month: '',
      death_year: '',
      death_country: '',
      death_region: '',
      death_city: '',
      sex: sex || '',
      alive: 'both',
    };

    for (const value in searchParams) {
      if (middleName?.length > 0) {
        searchParams.first_name2 = middleName?.toString();
      }

      if (birthDay?.length > 0) {
        searchParams.birth_day = birthDay?.toString();
      }

      if (birthMonth?.length > 0) {
        searchParams.birth_month = birthMonth?.toString();
      }

      if (birthYear?.length > 0) {
        searchParams.birth_year = birthYear?.toString();
      }

      if (deathDay?.length > 0) {
        searchParams.death_day = deathDay?.toString();
      }

      if (deathMonth?.length > 0) {
        searchParams.death_month = deathMonth?.toString();
      }

      if (deathYear?.length > 0) {
        searchParams.death_year = deathYear?.toString();
      }

      if (sex) {
        searchParams.sex = sex?.toString() || 'both';
      }

      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (searchParams[value] as string)?.length === 0 ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !searchParams[value]
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete searchParams[value];
      }
    }

    const domainName = countries?.find((countryFromProps) => {
      return (
        countryFromProps?.iso_3?.toLowerCase() === countryIso3?.toLowerCase()
      );
    })?.api_public_domain_name as string;

    const humansFromSearchAPI = await searchByFilters(
      firstName,
      lastName,
      countryIso3,
      '1',
      '100',
      new URLSearchParams(searchParams)?.toString(),
      domainName
    );

    const humanFromSearchAPI =
      humansFromSearchAPI?.data?.length > 0
        ? humansFromSearchAPI?.data?.find(
            (humanFromSearch: { id: string }) => humanFromSearch?.id === id
          )
        : {};

    const dataToConcat = () => {
      const birthObject = !isEmpty(humanFromSearchAPI)
        ? [
            {
              field_name: 'birth_country',
              new_value: humanFromSearchAPI?.birth?.country,
            },
            {
              field_name: 'birth_city',
              new_value: humanFromSearchAPI?.birth?.city,
            },
            {
              field_name: 'birth_region',
              new_value: humanFromSearchAPI?.birth?.region,
            },
          ]
        : [];
      const deathObject = !isEmpty(humanFromSearchAPI)
        ? [
            {
              field_name: 'death_country',
              new_value: humanFromSearchAPI?.death?.country,
            },
            {
              field_name: 'death_city',
              new_value: humanFromSearchAPI?.death?.city,
            },
            {
              field_name: 'death_region',
              new_value: humanFromSearchAPI?.death?.region,
            },
          ]
        : [];
      if (!isEmpty(birthObject) && !isEmpty(deathObject)) {
        return birthObject?.concat(deathObject);
      }

      if (!isEmpty(birthObject) && isEmpty(deathObject)) {
        return birthObject;
      }

      if (isEmpty(birthObject) && !isEmpty(deathObject)) {
        return deathObject;
      }

      return [];
    };

    const humanArray =
      dataToConcat()?.length > 0 ? human?.concat(dataToConcat()) : human;

    setHumanFromClient(humanArray as HumanTypes[]);
  }, [countries, router?.query?.human_slug, idForClientSideFetch]);

  useEffect(() => {
    if (triggerFetchClientSideHuman) {
      getHumanDataClientSide().then(() => {
        resetFileds();
        setTriggerFetchClientSideHuman(false);
      });
    }
  }, [getHumanDataClientSide, triggerFetchClientSideHuman]);

  const handleIsMale = () => {
    setIsMaleSex(true);
    setIsFemaleSex(false);
  };
  const handleIsFemale = () => {
    setIsMaleSex(false);
    setIsFemaleSex(true);
  };

  const [showAreYouSureModalForSex, setShowAreYouSureModalForSex] =
    useState(false);

  const [showReviewView, setShowReviewView] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      if (showReviewView && !wasSaved) {
        const result = window.confirm(
          'You have unsaved changes. Are you sure you want to leave?'
        );
        if (!result) {
          router.events.emit('routeChangeError', '', '', {
            shallow: false,
          });
          throw 'routeChange aborted.';
        }
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [showReviewView, wasSaved, router]);

  const reviewRecord = () => {
    const maleArrayWithDeath: MaleCompletionObjectTypes = {
      firstName1,
      firstName2,
      lastName,
      sex: isMaleSex ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const femaleArrayWithDeath: FemaleCompletionObjectTypes = {
      firstName1,
      firstName2,
      lastName,
      country,
      sex: isFemaleSex ? 'female' : '',
      maidenName,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
      deathDay,
      deathMonth,
      deathYear,
      deathCity,
      deathRegion,
      deathCountry,
    };

    const maleArrayWithoutDeath: Omit<
      MaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1,
      firstName2,
      lastName,
      sex: isMaleSex ? 'male' : '',
      country,
      birthDay,
      birthMonth,
      birthYear,
      birthCountry,
      birthCity,
      birthRegion,
    };

    const femaleArrayWithoutDeath: Omit<
      FemaleCompletionObjectTypes,
      | 'deathDay'
      | 'deathMonth'
      | 'deathYear'
      | 'deathCity'
      | 'deathRegion'
      | 'deathCountry'
    > = {
      firstName1,
      firstName2,
      lastName,
      country,
      sex: isFemaleSex ? 'female' : '',
      birthDay,
      maidenName,
      birthMonth,
      birthYear,
      birthCity,
      birthCountry,
      birthRegion,
    };

    const includesDeathRelatedInformation =
      deathCity?.length > 0 ||
      deathRegion?.length > 0 ||
      deathCountry?.length > 0 ||
      deathMonth?.length > 0 ||
      deathYear?.length > 0 ||
      deathDay?.length > 0;

    let completionObject;

    if (includesDeathRelatedInformation) {
      completionObject = isMaleSex ? maleArrayWithDeath : femaleArrayWithDeath;
    } else {
      completionObject = isMaleSex
        ? maleArrayWithoutDeath
        : femaleArrayWithoutDeath;
    }

    const completionNumber = calculateCompletionIndex(completionObject);

    const human_uuid = router?.query?.human_slug
      ?.toString()
      ?.substring(0, router?.query?.human_slug?.lastIndexOf('-'));

    const countryId = allCountriesWithRegions?.find(
      (locationCountry) =>
        locationCountry?.id?.toLowerCase() ===
        countryIso3FromProps?.toLowerCase()
    )?.id as string;

    const birthCountryPlace = allCountriesWithRegions?.find(
      (locationCountry) =>
        locationCountry?.place?.toLowerCase() === birthCountry?.toLowerCase()
    )?.place;

    const birthRegionPlace = listOfRegionsStates
      ?.find(
        (locationRegionState) =>
          locationRegionState?.place?.toLowerCase() ===
          birthRegion?.toLowerCase()
      )
      ?.place?.toString();

    const birthCityPlace = pathsForLocation
      ?.find(
        (locationPath) =>
          locationPath?.place?.toLowerCase() === birthCity?.toLowerCase()
      )
      ?.place?.toString();

    const deathCountryPlace = allCountriesWithRegions?.find(
      (locationCountry) =>
        locationCountry?.place?.toLowerCase() === deathCountry?.toLowerCase()
    )?.place as string;

    const deathRegionPlace = listOfRegionsStatesDeath
      ?.find(
        (locationRegionState) =>
          locationRegionState?.place?.toLowerCase() ===
          deathRegion?.toLowerCase()
      )
      ?.place?.toString();

    const deathCityPlace = pathsForLocationDeath
      ?.find(
        (locationPath) =>
          locationPath?.place?.toLowerCase() === deathCity?.toLowerCase()
      )
      ?.place?.toString();

    const humanObject: AddHumanRecord = {
      first_name: firstName1?.length > 0 ? firstName1 : undefined,
      first_name2: firstName2?.length > 0 ? firstName2.trim() : undefined,
      last_name: lastName?.length > 0 ? lastName : undefined,
      maiden_name: maidenName?.length > 0 ? maidenName.trim() : undefined,
      birth_margin: selectedBirthApproxValue ? '1' : '0',
      birth_year: parseInt(birthYear) > 0 ? birthYear : undefined,
      birth_month: parseInt(birthMonth) > 0 ? birthMonth : undefined,
      birth_day: parseInt(birthDay) > 0 ? birthDay : undefined,
      death_year: parseInt(deathYear) > 0 ? deathYear : undefined,
      death_month: parseInt(deathMonth) > 0 ? deathMonth : undefined,
      death_day: parseInt(deathDay) > 0 ? deathDay : undefined,
      edit_type: 'humanUpdate',
      is_inferred: isInferred,
      human_uuid: human_uuid,
      human_id: human_uuid?.split('_')?.[2],
      sex: isMaleSex ? 'male' : 'female',
      birth_city: birthCityPlace ? birthCityPlace : undefined,
      birth_country: birthCountryPlace ? birthCountryPlace : undefined,
      country: countryId?.length > 0 ? countryId : undefined, // countryId.length > 0 ? countryId : 'WOR',
      birth_region: birthRegionPlace ? birthRegionPlace : undefined,
      death_city: deathCityPlace || undefined,
      death_country: deathCountryPlace || undefined,
      death_region: deathRegionPlace || undefined,
      completion_index: completionNumber,
    };

    const cleanedHumanObject = pickBy(
      humanObject,
      (value) => value !== undefined
    ) as SetStateAction<AddHumanRecord | null>;

    setHumanRecord(cleanedHumanObject);

    if (!isAnySexSelected) {
      setShowAreYouSureModalForSex(true);
    } else {
      setShowReviewView(true);
    }
  };

  const closeAreYouSureModalForSex = () => {
    setShowAreYouSureModalForSex(false);
  };

  const changeToReviewView = () => {
    setShowAreYouSureModalForSex(false);
    setShowReviewView(true);
  };

  const mostVisitedCountriesIds = getCookie('mostVisitedCountriesIds');

  const getMostVisitedCountries: () => DataWorldChartTypes[] | [] | undefined =
    useCallback(() => {
      if (mostVisitedCountriesIds) {
        const visitedCountries =
          JSON.parse(mostVisitedCountriesIds as string) || [];
        // const foundCountries = (countries as [])?.
        const foundCountries = [];
        for (let i = 0; i < (countries as DataWorldChartTypes[])?.length; i++) {
          for (let j = 0; j < visitedCountries?.length; j++) {
            if (
              (countries as DataWorldChartTypes[])[i].ID === visitedCountries[j]
            ) {
              const foundCountry = {
                ...(countries as DataWorldChartTypes[])[i],
                visited: true,
              };
              foundCountries.push(foundCountry);
            }
          }
        }

        if (foundCountries) {
          return foundCountries;
        }
      } else {
        return [];
      }
    }, [countries, mostVisitedCountriesIds]);

  const goBackReviewRecord = () => {
    setShowReviewView(false);
    if (
      router?.query?.uuid &&
      router?.query?.status &&
      router?.query?.country
    ) {
      router?.replace({
        pathname: `/${router?.query?.human_slug}`,
        query: {},
      });
    }
  };

  const resetFileds = () => {
    setFirstName1('');
    setMaidenName('');
    setFirstName2('');
    setLastName('');
    setValueToRecordForDateBirth('');
    setBirthDay('');
    setBirthMonth('');
    setBirthYear('');
    setDeathDay('');
    setDeathMonth('');
    setDeathYear('');
    setValueToRecordForDeath('');
    setBirthCountry('');
    setSearchTermBirthCountry('');
    setBirthRegion('');
    setSearchTermBirthRegion('');
    setBirthCity('');
    setSearchTermBirthCity('');
    setDeathCountry('');
    setSearchTermDeathCountry('');
    setDeathRegion('');
    setSearchTermDeathRegion('');
    setDeathCity('');
    setSearchTermDeathCity('');
  };

  const [hoverFocusIndex, setHoverFocusIndex] = useState<null | number>(null);

  const loadEditIcon = (index: number) => () => {
    setHoverFocusIndex(index);
  };

  const onHoverOver = () => {
    setHoverFocusIndex(null);
  };

  const dispatch = useDispatch();

  type optionTypesForCreateEditTypes = 'DISMISS' | 'SURF AS A GUEST' | 'LOGIN';

  const selectCreateEditOption =
    (option: optionTypesForCreateEditTypes) => () => {
      if (option === 'DISMISS' && !isLoggedIn) {
        dispatch(
          userActions.setEditCreateOption({
            editCreateOption: 'dissmissed-as-guest',
          })
        );
        setCookie('user', ip, 10800); // 3 hours
      }
      if (option === 'LOGIN') {
        router.push('/sign-in');
      }
      if (option === 'SURF AS A GUEST') {
        // ! Logout the user if logged in
        deleteCookie('userToken');
        dispatch(userActions.setIsUserLoggedIn({ isLoggedIn: false }));
        dispatch(userActions.setUserName({ userName: null }));
        setCookie('user', ip, 10800); // 3 hours
        deleteCookie('rememberMe');
      }
    };

  const getAllBirthCountries = useCallback(() => {
    const searchTerm = searchTermBirthCountry;
    const finalData = allCountriesWithRegions;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const countryId =
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.id?.toLowerCase() === country?.toLowerCase()
      )?.id as string) ||
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.place?.toLowerCase() === country?.toLowerCase()
      )?.id as string);

    const countryName =
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.id?.toLowerCase() === country?.toLowerCase()
      )?.place as string) ||
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.place?.toLowerCase() === country?.toLowerCase()
      )?.place as string);

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) => location?.place !== 'United States Minor Outlying Islands'
    );

    const searchResultsAfterCleaningWithoutCountryName = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) =>
        location?.place !== 'United States Minor Outlying Islands' &&
        location?.place?.toLowerCase() !== countryName?.toLowerCase()
    );

    if (
      searchResultsAfterCleaning?.length === 1 &&
      birthCountry === searchTerm
    ) {
      const filteredFinalData = finalData?.filter(
        (countryFromData) =>
          countryFromData?.place?.toLowerCase() !== countryName?.toLowerCase()
      );

      const newArray = (
        [
          {
            place: `Choose country`,
            id: countryId,
          },
        ] as LocationsTypes[]
      ).concat(filteredFinalData);

      return newArray;
    } else {
      return countryName
        ? (
            [
              {
                place: countryName,
                id: countryId,
              },
            ] as LocationsTypes[]
          ).concat(searchResultsAfterCleaningWithoutCountryName)
        : searchResultsAfterCleaning;
    }
  }, [country, searchTermBirthCountry, birthCountry]);

  const getAllCountriesDeath = useCallback(() => {
    const searchTerm = searchTermDeathCountry;
    const finalData = allCountriesWithRegions;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const countryId =
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.id?.toLowerCase() === country?.toLowerCase()
      )?.id as string) ||
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.place?.toLowerCase() === country?.toLowerCase()
      )?.id as string);

    const countryName =
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.id?.toLowerCase() === country?.toLowerCase()
      )?.place as string) ||
      (allCountriesWithRegions?.find(
        (locationCountry) =>
          locationCountry?.place?.toLowerCase() === country?.toLowerCase()
      )?.place as string);

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) => location?.place !== 'United States Minor Outlying Islands'
    );

    const searchResultsAfterCleaningWithoutCountryName = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) =>
        location?.place !== 'United States Minor Outlying Islands' &&
        location?.place?.toLowerCase() !== countryName?.toLowerCase()
    );

    if (
      searchResultsAfterCleaning?.length === 1 &&
      deathCountry === searchTerm
    ) {
      const filteredFinalData = finalData?.filter(
        (countryFromData) =>
          countryFromData?.place?.toLowerCase() !== countryName?.toLowerCase()
      );

      const newArray = (
        [
          {
            place: `Choose country`,
            id: countryId,
          },
        ] as LocationsTypes[]
      ).concat(filteredFinalData);
      return newArray;
    } else {
      return countryName
        ? (
            [
              {
                place: countryName,
                id: countryId,
              },
            ] as LocationsTypes[]
          ).concat(searchResultsAfterCleaningWithoutCountryName)
        : searchResultsAfterCleaning;
    }
  }, [searchTermDeathCountry, country, deathCountry]);

  const [numberOfBirthCountries, setNumberOfBirthCountries] = useState(0);
  const [numberOfCountriesDeath, setNumberOfCountriesDeath] = useState(0);

  useEffect(() => {
    setNumberOfBirthCountries(getAllBirthCountries()?.length);
    setNumberOfCountriesDeath(getAllCountriesDeath()?.length);
    setDeathCountryCursorSelection(0);
  }, [getAllBirthCountries, getAllCountriesDeath]);

  const [showRegionState, setShowRegionState] = useState<undefined | boolean>(
    undefined
  );

  const [showRegionStateDeath, setShowRegionStateDeath] = useState<
    undefined | boolean
  >(undefined);
  const [typeOfSubdivision, setTypeOfSubdivision] = useState('State');
  const [typeOfSubdivisionDeath, setTypeOfSubdivisionDeath] = useState('State');

  const [listOfRegionsStates, setListOfRegionsState] = useState<
    ListRegionStatesTypes[]
  >([]);
  const [listOfRegionsStatesDeath, setListOfRegionsStateDeath] = useState<
    ListRegionStatesTypes[]
  >([]);
  const [pathsForLocation, setPathsForLocation] = useState<PathTypes[]>([]);
  const [pathsForLocationDeath, setPathsForLocationDeath] = useState<
    PathTypes[]
  >([]);
  const previousPathsForLocation = usePrevious(pathsForLocation);
  const previousPathsForLocationDeath = usePrevious(pathsForLocationDeath);

  const getListOfRegionsState = useCallback(() => {
    const searchTerm =
      searchTermBirthRegion === `I don't know` ? '' : searchTermBirthRegion;
    const finalData = listOfRegionsStates;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ];

    if (
      searchResultsAfterCleaning?.length === 1 &&
      birthRegion === searchTerm
    ) {
      const newArray = (
        [
          {
            place: `Choose ${typeOfSubdivision?.toLocaleLowerCase()}`,
            id: '0',
          },
        ] as ListRegionStatesTypes[]
      ).concat(finalData as ListRegionStatesTypes[]);
      return newArray;
    } else {
      return searchResultsAfterCleaning;
    }
  }, [
    searchTermBirthRegion,
    listOfRegionsStates,
    birthRegion,
    typeOfSubdivision,
  ]);

  const getPathsForLocation = useCallback(() => {
    const searchTerm =
      searchTermBirthCity === `I don't know` ? '' : searchTermBirthCity;
    const finalData = pathsForLocation;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) => location?.place !== 'United States Minor Outlying Islands'
    );

    if (
      searchResultsAfterCleaning?.length === 1 &&
      birthCity === searchTermBirthCity
    ) {
      const newArray = (
        [
          {
            place: 'Choose city',
            id: '0',
          },
        ] as PathTypes[]
      ).concat(finalData);

      return newArray;
    } else {
      return searchResultsAfterCleaning;
    }
  }, [searchTermBirthCity, pathsForLocation, birthCity]);

  const getListOfRegionsStateDeath = useCallback(() => {
    const searchTerm =
      searchTermDeathRegion === `I don't know` ? '' : searchTermDeathRegion;
    const finalData = listOfRegionsStatesDeath;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) => location?.place !== 'United States Minor Outlying Islands'
    );

    if (
      searchResultsAfterCleaning?.length === 1 &&
      deathRegion === searchTerm
    ) {
      const newArray = (
        [
          {
            place: `Choose ${typeOfSubdivisionDeath?.toLocaleLowerCase()}`,
            id: '0',
          },
        ] as ListRegionStatesTypes[]
      ).concat(finalData as ListRegionStatesTypes[]);

      return newArray;
    } else {
      return searchResultsAfterCleaning;
    }
  }, [
    deathRegion,
    searchTermDeathRegion,
    listOfRegionsStatesDeath,
    typeOfSubdivisionDeath,
  ]);

  const getPathsForLocationDeath = useCallback(() => {
    const searchTerm =
      searchTermDeathCity === `I don't know` ? '' : searchTermDeathCity;
    const finalData = pathsForLocationDeath;

    const searchResults = finalData?.filter((location) =>
      location?.place?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const key = 'place';

    const searchResultsAfterCleaning = [
      ...new Map(searchResults?.map((item) => [item[key], item])).values(),
    ]?.filter(
      (location) => location?.place !== 'United States Minor Outlying Islands'
    );

    if (searchResultsAfterCleaning?.length === 1 && deathCity === searchTerm) {
      const newArray = (
        [
          {
            place: 'Choose city',
            id: '0',
          },
        ] as PathTypes[]
      ).concat(finalData);

      return newArray;
    } else {
      return searchResultsAfterCleaning;
    }
  }, [searchTermDeathCity, pathsForLocationDeath, deathCity]);

  const [getPathsForLocationDeathVar, setGetPathsForLocationDeathVar] =
    useState<PathTypes[] | undefined>(undefined);
  const [getPathsForLocationVar, setGetPathsForLocationVar] = useState<
    PathTypes[] | undefined
  >(undefined);

  useEffect(() => {
    const deathCities = getPathsForLocationDeath();
    if (deathCities !== previousPathsForLocationDeath) {
      setGetPathsForLocationDeathVar(deathCities as PathTypes[]);
    } else {
      setGetPathsForLocationDeathVar([] as PathTypes[]);
    }
    const birthCities = getPathsForLocation();
    if (birthCities !== previousPathsForLocation) {
      setGetPathsForLocationVar(birthCities as PathTypes[]);
    } else {
      setGetPathsForLocationVar([] as PathTypes[]);
    }
  }, [
    getPathsForLocation,
    getPathsForLocationDeath,
    previousPathsForLocationDeath,
    previousPathsForLocation,
  ]);

  const resetAllBirthLocationData = () => {
    setBirthCountry('');
    setSearchTermBirthCountry('');
    setBirthCity('');
    setSearchTermBirthCity('');
    setBirthRegion('');
    setSearchTermBirthRegion('');
    setBirthRegionPath('');
  };

  const resetAllDeathLocationData = () => {
    setDeathCountry('');
    setSearchTermDeathCountry('');
    setDeathCity('');
    setSearchTermDeathCity('');
    setDeathRegion('');
    setSearchTermDeathRegion('');
    setDeathRegionPath('');
  };

  const resetBirthRegionAndCityData = () => {
    setBirthCity('');
    setSearchTermBirthCity('');
    setBirthRegion('');
    setSearchTermBirthRegion('');
    setBirthRegionPath('');
  };

  const resetDeathRegionAndCityData = () => {
    setDeathCity('');
    setSearchTermDeathCity('');
    setDeathRegion('');
    setSearchTermDeathRegion('');
    setDeathRegionPath('');
  };

  const resetBirthCityData = () => {
    setBirthCity('');
    setSearchTermBirthCity('');
  };

  const resetDeathCityData = () => {
    setDeathCity('');
    setSearchTermDeathCity('');
  };

  const [deathCountryCursorSelection, setDeathCountryCursorSelection] =
    useState(0);

  const firstCondition = useMemo(
    () =>
      !isTypingInFirstName1 &&
      !isTypingInFirstName2 &&
      !isTypingInLastName &&
      !isTypingInMaidenName &&
      !isTypingInDeathLocation &&
      !isTypingInBirthLodation &&
      !isLoadingGetLocation &&
      !isLoadingGetLocationPath &&
      !isLoadingGetLocationPathDeath,
    [
      isTypingInBirthLodation,
      isTypingInDeathLocation,
      isTypingInFirstName1,
      isTypingInFirstName2,
      isTypingInLastName,
      isTypingInMaidenName,
      isLoadingGetLocation,
      isLoadingGetLocationPath,
      firstName1,
      lastName,
      country,
      isLoadingGetLocationPathDeath,
    ]
  );

  const secondCondition =
    Boolean(listOfRegionsStates) && Boolean(listOfRegionsStatesDeath);

  const [deleteReasonMessage, setDeleteReasonMessage] = useState('');
  const [deleteReasonError, setDeleteReasonError] = useState(true);

  const handleReasonAreaMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDeleteReasonMessage(e?.target?.value);

    if (e?.target?.value?.length < 20) {
      setDeleteReasonError(true);
    } else {
      setDeleteReasonError(false);
    }
  };

  const handleDeleteRecord = async () => {
    const human_uuid = router?.query?.human_slug?.toString();

    const usernameOrIp = userId ? userName : ip;

    const reason = deleteReasonMessage;

    suggestDeletionEmail(human_uuid ?? '', reason, usernameOrIp as string)
      .then((res) => {
        if (res?.message === 'email sent') {
          displayToast(
            'Your suggestion will be reviewed',
            'success',
            <SuccessIcon />
          );

          setShowDeletionModal(false);
          setDeleteReasonMessage('');
        } else {
          displayToast(
            'There was an error sending the suggestion',
            'error',
            <ErrorIcon />
          );
        }
      })
      .catch(() => {
        displayToast('There was an error', 'error', <ErrorIcon />);
      });
  };

  const [isApplyingHumanData, setIsApplyingHumanData] = useState(false);

  const applyHumanData = useCallback(
    (humanToUse: HumanTypes[]) => {
      const firstNameFromHuman =
        humanToUse?.find((person) => person.field_name === 'first_name')
          ?.new_value || '';

      const middleNameFromHuman =
        humanToUse?.find((person) => person.field_name === 'first_name2')
          ?.new_value || '';

      const lastNameFromHuman =
        humanToUse?.find((person) => person.field_name === 'last_name')
          ?.new_value || '';

      const isInferredFromHuman =
        humanToUse?.find((person) => person.field_name === 'is_inferred')
          ?.new_value || false;

      const maidenNameFromHuman =
        humanToUse?.find((person) => person.field_name === 'maiden_name')
          ?.new_value || '';

      // Birth Data
      const birthDayFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'birth_date')
          ?.new_value?.split('-')?.[2]
          ?.trim() !== '00'
          ? (humanToUse
              ?.find((person) => person.field_name === 'birth_date')
              ?.new_value?.split('-')?.[2] as string) || ''
          : '' || '';

      const birthMonthFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'birth_date')
          ?.new_value?.split('-')?.[1] !== '00'
          ? (humanToUse
              ?.find((person) => person.field_name === 'birth_date')
              ?.new_value?.split('-')?.[1] as string) || ''
          : '' || '';

      const birthYearFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'birth_date')
          ?.new_value?.split('-')?.[0]
          ?.trim() !== '00'
          ? moment(
              humanToUse
                ?.find((person) => person.field_name === 'birth_date')
                ?.new_value?.split('-')?.[0],
              ['YYYY']
            ).format('YYYY') || ''
          : '' || '';

      const birthRegionFromHuman =
        humanToUse?.find((person) => person.field_name === 'birth_region')
          ?.new_value || '';

      const birthRegionPathFromHuman = birthRegionFromHuman
        ? (listOfRegionsStates?.find(
            (region) =>
              region?.place?.toLowerCase() ===
              birthRegionFromHuman?.toLowerCase()
          )?.path as string) || ''
        : '';

      const birthCityFromHuman =
        humanToUse?.find((person) => person.field_name === 'birth_city')
          ?.new_value || '';

      const birthCountryFromHuman =
        humanToUse?.find((person) => person.field_name === 'birth_country')
          ?.new_value || '';

      // Death Data
      const deathDayFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'death_date')
          ?.new_value?.split('-')?.[2]
          ?.trim() !== '00'
          ? (humanToUse
              ?.find((person) => person.field_name === 'death_date')
              ?.new_value?.split('-')?.[2] as string) || ''
          : '' || '';

      const deathMonthFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'death_date')
          ?.new_value?.split('-')?.[1] !== '00'
          ? (humanToUse
              ?.find((person) => person.field_name === 'death_date')
              ?.new_value?.split('-')?.[1] as string) || ''
          : '' || '';

      const deathYearFromHuman =
        humanToUse
          ?.find((person) => person.field_name === 'death_date')
          ?.new_value?.split('-')?.[0]
          ?.trim() !== '00'
          ? moment(
              humanToUse
                ?.find((person) => person.field_name === 'death_date')
                ?.new_value?.split('-')?.[0],
              ['YYYY']
            ).format('YYYY') || ''
          : '' || '';

      if (!firstName2) setFirstName2(formatName(middleNameFromHuman));

      const deathCountryFromHuman =
        humanToUse?.find((person) => person.field_name === 'death_country')
          ?.new_value || '';

      const deathRegionFromHuman =
        humanToUse?.find((person) => person.field_name === 'death_region')
          ?.new_value || '';

      const deathRegionPathFromHuman = deathRegionFromHuman
        ? (listOfRegionsStatesDeath?.find(
            (region) => region?.path === deathRegionFromHuman?.toLowerCase()
          )?.path as string) || ''
        : '';

      const deathCityFromHuman =
        humanToUse?.find((person) => person.field_name === 'death_city')
          ?.new_value || '';

      if (
        firstName1?.length === 0 &&
        firstName2?.length === 0 &&
        lastName?.length === 0 &&
        birthDay?.length === 0 &&
        birthMonth?.length === 0 &&
        birthYear?.length === 0 &&
        birthCountry?.length === 0 &&
        searchTermBirthCountry?.length === 0 &&
        birthRegion?.length === 0 &&
        searchTermBirthRegion?.length === 0 &&
        birthCity?.length === 0 &&
        searchTermBirthCity?.length === 0 &&
        deathDay?.length === 0 &&
        deathMonth?.length === 0 &&
        deathYear?.length === 0 &&
        deathCountry?.length === 0 &&
        searchTermDeathCountry?.length === 0 &&
        deathRegion?.length === 0 &&
        searchTermDeathRegion?.length === 0 &&
        deathCity?.length === 0 &&
        searchTermDeathCity?.length === 0
      ) {
        setFirstName1(formatName(firstNameFromHuman));

        setLastName(formatName(lastNameFromHuman));
        setMaidenName(formatName(maidenNameFromHuman));
        setBirthDay(
          birthDayFromHuman === 'Invalid date'
            ? ''
            : (birthDayFromHuman as string)
        );
        setBirthMonth(
          birthMonthFromHuman === 'Invalid date'
            ? ''
            : (birthMonthFromHuman as string)
        );
        setBirthYear(
          birthYearFromHuman === 'Invalid date'
            ? ''
            : (birthYearFromHuman as string)
        );

        if (
          birthDayFromHuman?.length > 0 &&
          birthMonthFromHuman?.length > 0 &&
          birthYearFromHuman?.length > 0 &&
          birthDayFromHuman !== 'Invalid date' &&
          birthMonthFromHuman !== 'Invalid date' &&
          birthYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDateBirth(
            `${birthMonthFromHuman}/${birthDayFromHuman}/${birthYearFromHuman}`
          );
        }

        if (
          birthDayFromHuman?.length === 0 &&
          birthMonthFromHuman?.length > 0 &&
          birthYearFromHuman?.length > 0 &&
          birthMonthFromHuman !== 'Invalid date' &&
          birthYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDateBirth(
            `${birthMonthFromHuman}/${birthYearFromHuman}`
          );
        }

        setCountry(
          humanToUse?.find((person) => person.field_name === 'country')
            ?.new_value || ''
        );

        if (
          birthDayFromHuman?.length === 0 &&
          birthMonthFromHuman?.length === 0 &&
          birthYearFromHuman?.length > 0 &&
          birthYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDateBirth(`${birthYearFromHuman}`);
        }

        setBirthCountry(birthCountryFromHuman);
        setSearchTermBirthCountry(birthCountryFromHuman);
        setBirthRegion(toTitleCase(birthRegionFromHuman));
        setSearchTermBirthRegion(toTitleCase(birthRegionFromHuman));
        setBirthCity(toTitleCase(birthCityFromHuman));
        setSearchTermBirthCity(toTitleCase(birthCityFromHuman));
        setDeathDay(
          deathDayFromHuman === 'Invalid date'
            ? ''
            : (deathDayFromHuman as string)
        );
        setDeathMonth(
          deathMonthFromHuman === 'Invalid date'
            ? ''
            : (deathMonthFromHuman as string)
        );
        setDeathYear(
          deathYearFromHuman === 'Invalid date'
            ? ''
            : (deathYearFromHuman as string)
        );

        if (
          deathDayFromHuman?.length > 0 &&
          deathMonthFromHuman?.length > 0 &&
          deathYearFromHuman?.length > 0 &&
          deathDayFromHuman !== 'Invalid date' &&
          deathMonthFromHuman !== 'Invalid date' &&
          deathYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDeath(
            `${deathMonthFromHuman}/${deathDayFromHuman}/${deathYearFromHuman}`
          );
        }

        if (
          deathDayFromHuman?.length === 0 &&
          deathMonthFromHuman?.length > 0 &&
          deathYearFromHuman?.length > 0 &&
          deathMonthFromHuman !== 'Invalid date' &&
          deathYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDeath(
            `${deathMonthFromHuman}/${deathYearFromHuman}`
          );
        }

        if (
          deathDayFromHuman?.length === 0 &&
          deathMonthFromHuman?.length === 0 &&
          deathYearFromHuman?.length > 0 &&
          deathYearFromHuman !== 'Invalid date'
        ) {
          setValueToRecordForDeath(`${deathYearFromHuman}`);
        }
        const humanToUseSexValue = humanToUse?.find(
          (person) => person.field_name === 'sex'
        )?.new_value;
        // Sex Data
        if (humanToUseSexValue) {
          setIsAnySexSelected(true);
          if (
            humanToUse
              ?.find((person) => person.field_name === 'sex')
              ?.new_value?.toString()
              ?.includes('2')
          ) {
            setIsFemaleSex(true);
          } else if (humanToUseSexValue != '0') {
            setIsMaleSex(true);
          }
        } else {
          setIsAnySexSelected(false);
        }

        setDeathCountry(toTitleCase(deathCountryFromHuman));
        setSearchTermDeathCountry(toTitleCase(deathCountryFromHuman));
        setDeathRegion(deathRegionFromHuman);
        setSearchTermDeathRegion(deathRegionFromHuman);
        setDeathCity(deathCityFromHuman);
        setSearchTermDeathCity(deathCityFromHuman);
        setIsInferred(isInferredFromHuman);
        setBirthRegionPath(birthRegionPathFromHuman as string);
        setDeathRegionPath(deathRegionPathFromHuman as string);

        setInitialHuman({
          firstName1: firstNameFromHuman?.toLowerCase() || '',
          firstName2: middleNameFromHuman?.toLowerCase() || '',
          lastName: lastNameFromHuman?.toLowerCase() || '',
          maidenName: maidenNameFromHuman?.toLowerCase() || '',
          birthDay:
            birthDayFromHuman === 'Invalid date'
              ? ''
              : (birthDayFromHuman as string) || '',
          birthMonth:
            birthMonthFromHuman === 'Invalid date'
              ? ''
              : (birthMonthFromHuman as string) || '',
          birthYear:
            birthYearFromHuman === 'Invalid date'
              ? ''
              : (birthYearFromHuman as string) || '',

          birthCountry: birthCountryFromHuman?.toLowerCase() || '',
          birthRegion: birthRegionFromHuman?.toLowerCase() || '',
          birthCity: birthCityFromHuman?.toLowerCase() || '',
          deathDay:
            deathDayFromHuman === 'Invalid date'
              ? ''
              : (deathDayFromHuman as string) || '',
          deathMonth:
            deathMonthFromHuman === 'Invalid date'
              ? ''
              : (deathMonthFromHuman as string) || '',
          deathYear:
            deathYearFromHuman === 'Invalid date'
              ? ''
              : (deathYearFromHuman as string) || '',
          deathCountry: deathCountryFromHuman?.toLowerCase() || '',
          deathRegion: deathRegionFromHuman?.toLowerCase() || '',
          deathCity: deathCityFromHuman?.toLowerCase() || '',
          sex: humanToUse
            ?.find((person) => person.field_name === 'sex')
            ?.new_value?.toString()
            ? humanToUse
                ?.find((person) => person.field_name === 'sex')
                ?.new_value?.toString() &&
              !humanToUse
                ?.find((person) => person.field_name === 'sex')
                ?.new_value?.toString()
                ?.includes('2')
              ? 'male'
              : 'female'
            : 'both',
        });
      }
      setIsApplyingHumanData(false);
    },
    [
      birthCity,
      birthCountry,
      birthDay,
      birthMonth,
      birthRegion,
      birthYear,
      countries,
      countryIso3FromProps,
      deathCity,
      deathCountry,
      deathDay,
      deathMonth,
      deathYear,
      deathRegion,
      firstName1,
      firstName2,
      lastName,
      country,
      listOfRegionsStates,
      listOfRegionsStatesDeath,
      searchTermBirthCity,
      searchTermBirthCountry,
      searchTermBirthRegion,
      searchTermDeathCity,
      searchTermDeathCountry,
      searchTermDeathRegion,
      isMaleSex,
      isFemaleSex,
    ]
  );

  // * START Mounting, Updating state ----------------------------------------------------------------------------------------------------------------------------------------------------------------
  // Update state with user data

  useEffect(() => {
    if (isMaleSex) {
      setMaidenName('');
    }

    if (!isMaleSex && !isFemaleSex) {
      setIsAnySexSelected(false);
      setNumberOfSimilarRecords(0);
      setIsUniqueRecord(false);
      setIsValidRecord(false);
    }

    if (isMaleSex || isFemaleSex) {
      setIsAnySexSelected(true);
    }
  }, [isMaleSex, isFemaleSex]);

  useEffect(() => {
    if (!isEmpty(initalHuman)) {
      const humanObject = {
        firstName1: firstName1?.toLowerCase() || '',
        firstName2: firstName2?.toLowerCase() || '',
        lastName: lastName?.toLowerCase() || '',
        maidenName: maidenName?.toLowerCase() || '',
        birthDay: birthDay || '',
        birthMonth: birthMonth || '',
        birthYear: birthYear || '',
        birthCountry: birthCountry?.toLowerCase() || '',
        birthRegion: birthRegion?.toLowerCase() || '',
        birthCity: birthCity?.toLowerCase() || '',
        deathDay: deathDay || '',
        deathMonth: deathMonth || '',
        deathYear: deathYear || '',
        deathCountry: deathCountry?.toLowerCase() || '',
        deathRegion: deathRegion?.toLowerCase() || '',
        deathCity: deathCity?.toLowerCase() || '',
        sex: isAnySexSelected
          ? isMaleSex
            ? 'male'
            : 'female'
          : initalHuman?.sex,
      };

      setNewHuman(humanObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    birthCity,
    birthCountry,
    birthDay,
    birthMonth,
    birthRegion,
    birthYear,
    deathCity,
    deathCountry,
    deathDay,
    deathMonth,
    deathRegion,
    deathYear,
    firstName1,
    firstName2,
    lastName,
    maidenName,
    isMaleSex,
    isFemaleSex,
    isAnySexSelected,
    initalHuman,
  ]);

  useEffect(() => {
    getCompletionBarWidth();

    if (!isApplyingHumanData) {
      if (
        firstName1?.length === 0 ||
        lastName?.length === 0 ||
        country?.length === 0
      ) {
        setShowTable(false);
        setArrayFromServer([]);
        setNumberOfSimilarRecords(0);
        setIsUniqueRecord(false);
        setIsValidRecord(false);
      }

      checkForValidOrUniqueRecord();

      if (zeroCondition && firstCondition) {
        searchForSimilarRecords();
      }
    }
  }, [
    zeroCondition,
    firstCondition,
    selectedBirthApproxValue,
    secondCondition,
    newHuman,
    isApplyingHumanData,
  ]);

  useEffect(() => {
    if (!SHOW_PAGE) {
      router.push('/404');
    } else {
      if (
        (humanFromProps || humanFromState) &&
        countryIso3FromProps &&
        secondCondition
      ) {
        const humanToUse = !humanFromState ? humanFromProps : humanFromState;
        if (humanToUse) {
          setIsApplyingHumanData(true);
          applyHumanData(humanToUse as HumanTypes[]);
        }
      }
    }
  }, [
    countryIso3FromProps,
    humanFromState,
    humanFromProps,
    secondCondition,
    applyHumanData,
  ]);

  // Set Type of Subdivision and List of Regions/State for birth country
  useEffect(() => {
    if (birthCountry?.length > 0) {
      const foundCountry = allCountriesWithRegions?.find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          birthCountry?.toLowerCase()
      );

      const foundCountryDomainName = countries?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if ((foundCountry?.region_label as string)?.length > 0) {
        setTypeOfSubdivision(foundCountry?.region_label as string);
      }

      if (foundCountry?.id) {
        setIsLoadingGetLocation(true);
        getLocation(foundCountry?.id, foundCountryDomainName)
          .then((res) => {
            setIsLoadingGetLocation(false);
            if (res) {
              setShowRegionState(true);
              setListOfRegionsState(res);
            }
          })
          .catch(() => setIsLoadingGetLocation(false));
      }
    } else {
      setIsLoadingGetLocation(false);
      setListOfRegionsState([]);
    }
  }, [birthCountry]);

  // Get cities for birth region
  useEffect(() => {
    const regionPath =
      (listOfRegionsStates?.find(
        (region) => region?.place?.toLowerCase() === birthRegion?.toLowerCase()
      )?.path as string) || '';
    if (
      listOfRegionsStates &&
      listOfRegionsStates?.length > 0 &&
      regionPath?.length > 0
    ) {
      const foundCountry = allCountriesWithRegions.find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          birthCountry?.toLowerCase()
      );

      const foundCountryDomainName = countries?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if (foundCountry?.id && regionPath) {
        setIsLoadingGetLocationPath(true);
        getLocationPath(
          foundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            setIsLoadingGetLocationPath(false);
            if (res) {
              setPathsForLocation(res);
              setGetPathsForLocationVar(res as PathTypes[]);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingGetLocationPath(false);
            setPathsForLocation([]);
            setGetPathsForLocationVar([]);
            // displayToast(`Can't get cities/paths`, 'error', <ErrorIcon />);
          });
      }
    } else {
      if (isLoadingGetLocationPath) {
        setIsLoadingGetLocationPath(false);
      }
    }
  }, [birthCountry, listOfRegionsStates, birthRegionPath, birthRegion]);

  // Set Type of Subdivision and List of Regions/State for death country
  useEffect(() => {
    if (deathCountry?.length > 0) {
      const foundCountry = allCountriesWithRegions.find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          deathCountry?.toLowerCase()
      );

      const foundCountryDomainName = countries?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if ((foundCountry?.region_label as string)?.length > 0) {
        setTypeOfSubdivisionDeath(foundCountry?.region_label as string);
      }

      if (foundCountry?.id) {
        getLocation(foundCountry?.id, foundCountryDomainName).then((res) => {
          if (res) {
            setShowRegionStateDeath(true);
            setListOfRegionsStateDeath(res);
          }
        });
      }
    } else {
      setShowRegionStateDeath(false);
      setListOfRegionsStateDeath([]);
    }
  }, [deathCountry]);

  // Get cities for death region
  useEffect(() => {
    const regionPath =
      (listOfRegionsStatesDeath?.find(
        (region) => region?.place?.toLowerCase() === deathRegion?.toLowerCase()
      )?.path as string) || '';
    if (
      listOfRegionsStatesDeath &&
      listOfRegionsStatesDeath?.length > 0 &&
      regionPath?.length > 0
    ) {
      const foundCountry = allCountriesWithRegions.find(
        (countryWithRegions) =>
          countryWithRegions?.place?.toLowerCase() ===
          deathCountry?.toLowerCase()
      );

      const foundCountryDomainName = countries?.find(
        (country) => country?.iso_3 === foundCountry?.id
      )?.api_public_domain_name as string;

      if (foundCountry?.id && regionPath) {
        setIsLoadingGetLocationPathDeath(true);
        getLocationPath(
          foundCountry?.id as string,
          regionPath,
          foundCountryDomainName
        )
          .then((res) => {
            setIsLoadingGetLocationPathDeath(false);
            if (res) {
              setPathsForLocationDeath(res);
              setGetPathsForLocationDeathVar(res as PathTypes[]);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoadingGetLocationPathDeath(false);
            setPathsForLocationDeath([]);
            setGetPathsForLocationDeathVar([]);
          });
      }
    } else {
      if (isLoadingGetLocationPathDeath) {
        setIsLoadingGetLocationPathDeath(false);
      }
    }
  }, [deathCountry, listOfRegionsStatesDeath, deathRegionPath, deathRegion]);

  useEffect(() => {
    if ((getMostVisitedCountries() as DataWorldChartTypes[])?.length > 0) {
      setCountry(
        countries?.find(
          (countryObj) => countryObj?.iso_3 === countryIso3FromProps
        )?.name || ''
      );
    }
  }, [countryIso3FromProps, countries]);

  const userCookie = getCookie('userCookies');
  const userRegistered = getCookie('user');

  useEffect(() => {
    if (userCookie === 'stricyly-necessary-cookies') {
      router
        ?.push({
          pathname: '/',
        })
        .then(() => {
          setIsCheckingUserCookieOption(false);
        });
      displayToast(
        `You can't edit a record with strictly necessary cookies only`,
        'error',
        <ErrorIcon />
      );
    } else {
      setIsCheckingUserCookieOption(false);
    }

    if (!SHOW_PAGE) {
      router.push('/404');
    }
    if (router?.asPath === '/sign-in' || router?.asPath === '/login') {
      router.push({
        pathname: '/sign-in',
      });
    }
  }, [userCookie]);

  const [
    showRecordStillProcessingMessage,
    setShowRecordStillProcessingMessage,
  ] = useState(false);

  useEffect(() => {
    if (
      router?.query?.status &&
      (router?.query?.uuid as string)?.length > 0 &&
      (router?.query?.country as string)?.length > 0
    ) {
      const uuid = router?.query?.uuid as string;
      const countryIso3 =
        (countries?.find(
          (countryProps) =>
            countryProps?.iso_3?.toLowerCase() ===
            (router?.query?.country as string)?.toLowerCase()
        )?.iso_3 as string) ||
        (countries?.find(
          (countryProps) =>
            countryProps?.name?.toLowerCase() ===
            (router?.query?.country as string)?.toLowerCase()
        )?.iso_3 as string);

      createHumanArray(uuid, countryIso3, null, countries).then((res) => {
        const humanArray = res;

        if (humanArray === "Couldn't get person history") {
          displayToast(
            'Record still being processed. It will be editable in a few minutes.',
            'info',
            <ErrorIcon />
          );
        }

        if (
          humanArray &&
          humanArray?.length &&
          typeof humanArray === 'object'
        ) {
          const firstNameFromHuman =
            humanArray?.find((person) => person.field_name === 'first_name')
              ?.new_value || '';

          const middleNameFromHuman =
            humanArray?.find((person) => person.field_name === 'first_name2')
              ?.new_value || '';

          const lastNameFromHuman =
            humanArray?.find((person) => person.field_name === 'last_name')
              ?.new_value || '';

          const maidenNameFromHuman =
            humanArray?.find((person) => person.field_name === 'maiden_name')
              ?.new_value || '';

          // Birth Data
          const birthDayFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'birth_date')
              ?.new_value?.split('-')?.[2]
              ?.trim() !== '00'
              ? (humanArray
                  ?.find((person) => person.field_name === 'birth_date')
                  ?.new_value?.split('-')?.[2] as string) || ''
              : '' || '';

          const birthMonthFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'birth_date')
              ?.new_value?.split('-')?.[1] !== '00'
              ? (humanArray
                  ?.find((person) => person.field_name === 'birth_date')
                  ?.new_value?.split('-')?.[1] as string) || ''
              : '' || '';

          const birthYearFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'birth_date')
              ?.new_value?.split('-')?.[0]
              ?.trim() !== '00'
              ? moment(
                  humanArray
                    ?.find((person) => person.field_name === 'birth_date')
                    ?.new_value?.split('-')?.[0],
                  ['YYYY']
                ).format('YYYY') || ''
              : '' || '';

          const birthRegionFromHuman =
            humanArray?.find((person) => person.field_name === 'birth_region')
              ?.new_value || '';

          const birthCityFromHuman =
            humanArray?.find((person) => person.field_name === 'birth_city')
              ?.new_value || '';

          const birthCountryFromHuman =
            humanArray?.find((person) => person.field_name === 'birth_country')
              ?.new_value || '';

          // Death Data
          const deathDayFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'death_date')
              ?.new_value?.split('-')?.[2]
              ?.trim() !== '00'
              ? (humanArray
                  ?.find((person) => person.field_name === 'death_date')
                  ?.new_value?.split('-')?.[2] as string) || ''
              : '' || '';

          const deathMonthFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'death_date')
              ?.new_value?.split('-')?.[1] !== '00'
              ? (humanArray
                  ?.find((person) => person.field_name === 'death_date')
                  ?.new_value?.split('-')?.[1] as string) || ''
              : '' || '';

          const deathYearFromHuman =
            humanArray
              ?.find((person) => person.field_name === 'death_date')
              ?.new_value?.split('-')?.[0]
              ?.trim() !== '00'
              ? moment(
                  humanArray
                    ?.find((person) => person.field_name === 'death_date')
                    ?.new_value?.split('-')?.[0],
                  ['YYYY']
                ).format('YYYY') || ''
              : '' || '';

          const deathCountryFromHuman =
            humanArray?.find((person) => person.field_name === 'death_country')
              ?.new_value || '';

          const deathRegionFromHuman =
            humanArray?.find((person) => person.field_name === 'death_region')
              ?.new_value || '';

          const deathCityFromHuman =
            humanArray?.find((person) => person.field_name === 'death_city')
              ?.new_value || '';

          const completionIndex =
            humanArray?.find(
              (person) => person.field_name === 'completion_index'
            )?.new_value || '';

          const editable =
            humanArray?.find((person) => person.field_name === 'editable')
              ?.new_value || '';

          const humanObject = {
            first_name: formatName(firstNameFromHuman),
            first_name2: formatName(middleNameFromHuman),
            last_name: formatName(lastNameFromHuman),
            maiden_name: formatName(maidenNameFromHuman),
            birth_day: birthDayFromHuman,
            birth_month: birthMonthFromHuman,
            birth_year:
              birthYearFromHuman === 'Invalid date' ? '' : birthYearFromHuman,
            country: countryIso3,
            birth_region: birthRegionFromHuman,
            birth_city: birthCityFromHuman,
            birth_country: birthCountryFromHuman,
            death_day: deathDayFromHuman,
            death_month: deathMonthFromHuman,
            death_year:
              deathYearFromHuman === 'Invalid date' ? '' : deathYearFromHuman,
            death_country: deathCountryFromHuman,
            death_region: deathRegionFromHuman,
            death_city: deathCityFromHuman,
            sex: humanArray
              ?.find((person) => person.field_name === 'sex')
              ?.new_value?.toString()
              ?.includes('1')
              ? 'male'
              : 'female',
            completion_index: completionIndex,
            editable: editable,
            edit_type: 'humanUpdate',
          };

          if (humanObject) {
            setShowReviewView(true);
            setHumanFromURL(humanObject as AddHumanRecord);
          }
        } else {
          setShowRecordStillProcessingMessage(true);
        }
      });
    }
  }, []);
  const handleMostVisitedCountriesIds = (
    countryFound: DataWorldChartTypes | undefined
  ) => {
    dispatch(
      searchActions.setSearchedCountry({
        searchedCountry: {
          ...countryFound,
          label: countryFound?.name,
          value: countryFound?.iso_2,
        },
      })
    );

    if (mostVisitedCountriesIds) {
      if (countryFound) {
        const newMostVisitedCountriesIds = [
          ...JSON.parse(mostVisitedCountriesIds as string),
        ];

        newMostVisitedCountriesIds.unshift(countryFound?.ID);

        const filteredMostVisitedCountriesIds = [
          ...new Set(newMostVisitedCountriesIds),
        ];

        if (newMostVisitedCountriesIds?.length < 4) {
          setCookie(
            'mostVisitedCountriesIds',
            JSON.stringify(filteredMostVisitedCountriesIds)
          );
        }
      }
    } else {
      setCookie(
        'mostVisitedCountriesIds',
        JSON.stringify([(countryFound as DataWorldChartTypes)?.ID])
      );
    }
  };

  const handleFirstName1 = (name: string) => {
    setFirstName1(formatName(name));
  };
  const handleLastName1 = (name: string) => {
    setLastName(formatName(name));
  };

  const handleMaidenName = (name: string) => {
    setMaidenName(name);
  };

  const handleFirstName2 = (name: string) => {
    setFirstName2(name);
  };

  const handleBirthCity = (city: string) => {
    setBirthCity(city);
    setSearchTermBirthCity(city);
  };

  const handleBirthRegion = (region: string) => {
    setBirthRegion(region);
  };

  const handleBirthCountry = (countryName: string) => {
    setBirthCountry(countryName);
  };

  const handleDeathCountry = (countryName: string) => {
    setDeathCountry(countryName);
  };

  const handleBirthDay = (e: ChangeEvent<HTMLInputElement>) => {
    setBirthDay(e?.target?.value);
  };

  const closeCountriesDropdown = () => {
    setCountriesDropdown(!countriesDropdown);
  };

  const onSelectCountry = (country: DataWorldChartTypes | undefined) => {
    const selectedCountry = country?.name as string;

    setCountry(selectedCountry);
    setSearchTermCountry(selectedCountry);
    closeCountriesDropdown();

    if (selectedCountry?.toLowerCase() === 'brazil') {
      // Brazil names don't use middle names
      setFirstName2('');
    }

    const countryFound = countries?.find(
      (countryFromProps) => countryFromProps.iso_3 === country?.iso_3
    );

    handleMostVisitedCountriesIds(countryFound);
  };

  const handleBirthMonth = (month: string) => {
    if (month === '0') {
      setBirthMonth('');
      setBirthDay('');

      return;
    }
    setBirthMonth(month);
  };

  const handleBirthYear = (e: ChangeEvent<HTMLInputElement>) => {
    setBirthYear(e?.target?.value);
  };

  const handleBirthMargin = (v: string) => {
    setSelectedBirthApproxValue(v === '1' ? 1 : 0);
  };

  const handleDeathDay = (e: ChangeEvent<HTMLInputElement>) => {
    setDeathDay(e?.target?.value);
  };

  const handleDeathMonth = (month: string) => {
    if (month === '0') {
      setDeathMonth('');
      return;
    }
    setDeathMonth(month);
  };

  const handleDeathYear = (e: ChangeEvent<HTMLInputElement>) => {
    setDeathYear(e?.target?.value);
  };

  const handleDeathCity = (city: string) => {
    setDeathCity(city);
  };

  const submitRecord = () => {
    if (editCreateOption || userRegistered) {
      const objectForAddHuman = userId
        ? {
            ...omit(humanRecord, ['completion_index']),
            user_id: userId?.toString(),
          }
        : {
            ...omit(humanRecord, ['completion_index']),
            ip_address: ip,
          };
      const domainName =
        (countries?.find(
          (country) =>
            country?.iso_3?.toLowerCase() ===
            objectForAddHuman?.country?.toLowerCase()
        )?.api_public_domain_name as string) ||
        (countries?.find(
          (country) =>
            country?.name?.toLowerCase() ===
            objectForAddHuman?.country?.toLowerCase()
        )?.api_public_domain_name as string);
      setIsCreating(true);
      setWasSaved?.(true);

      const {
        birth_margin,
        country: countryFromHumanObject,
        edit_type,
        is_inferred,
        human_id,
        user_id,
        ...restOfHumanUpdate
      } = objectForAddHuman;

      addHumanRecord(objectForAddHuman, domainName)
        .then((res) => {
          if (res?.status === 'Success') {
            // We need the time out to give some time to 4d to create the record.
            setTimeout(() => {
              if (router?.pathname === '/create-human') {
                router
                  ?.push(
                    {
                      pathname: `/human/${country
                        ?.toLowerCase()
                        ?.replaceAll(' ', '-')}/details/${res?.result?.data}`,
                      query: {
                        status: 'success',
                        uuid: res?.result?.data,
                        country: country,
                      },
                    },
                    undefined,
                    { shallow: true }
                  )
                  ?.then(() => {
                    trackCustomEvent(
                      'Human Created',
                      `${
                        isLoggedIn
                          ? 'Human created by user'
                          : 'Human created by guest'
                      }`,
                      `${objectForAddHuman?.first_name} ${
                        objectForAddHuman?.first_name2 || ''
                      } ${objectForAddHuman?.last_name}, ${
                        objectForAddHuman?.country
                      }`
                    );
                  });
              } else {
                router
                  ?.push(
                    {
                      pathname: `/human/${country
                        ?.toLowerCase()
                        ?.replaceAll(
                          ' ',
                          '-'
                        )}/details/${router.query.human_slug
                        ?.toString()
                        ?.split('-')
                        ?.at?.(0)}`,
                      query: {
                        status: 'success',
                        action: 'edit',
                        uuid: router.query.human_slug
                          ?.toString()
                          ?.split('-')
                          ?.at?.(0),
                        country: country,
                        ...restOfHumanUpdate,
                      },
                    },
                    undefined,
                    { shallow: true }
                  )
                  ?.then(() => {
                    trackCustomEvent(
                      'Edit',
                      `${isLoggedIn ? 'Edit by user' : 'Edit by guest'}`,
                      `${objectForAddHuman?.first_name} ${
                        objectForAddHuman?.first_name2 || ''
                      } ${objectForAddHuman?.last_name}, ${
                        objectForAddHuman?.country
                      }`
                    );
                  });
              }
            }, 1000);
          }

          if (res?.response?.status === 500) {
            displayToast(res?.response?.data?.message, 'error', <ErrorIcon />);
          }

          if (res?.result?.status === 'failure' || res?.status === 'Failed') {
            displayToast(res?.result?.message, 'error', <ErrorIcon />);
          }
        })
        .catch(() => {
          displayToast(
            'There was an issue trying to add the record',
            'error',
            <ErrorIcon />
          );
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else {
      displayToast(
        `Please indicate whether you want to be logged in or whether you prefer to surf as a guest`,
        'error',
        <ErrorIcon />
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        editActions.setSearchTermAttribute({ searchAttributeCameWith: null })
      );
    };
  }, []);

  useEffect(() => {
    getCompletionBarWidth();
  }, []);

  const { t } = useTranslation(['human-slug']);

  return (
    <div
      className={`flex min-h-screen flex-col bg-[#E5E5E5] max-md:pb-[120px]`}
    >
      <StandardSEOWrapper
        title={t('human-slug:seo.title')}
        description={t('human-slug:seo.description')}
      />
      {(showAreYouSureModalForSex ||
        showDeletionModal ||
        showRecordStillProcessingMessage) && (
        <style>
          {`
             body, html {
              height: 100% !important;
              overflow: hidden !important;
        `}
        </style>
      )}
      {showRecordStillProcessingMessage && (
        <RecordStillProcessing countries={countries} linkedPage={false} />
      )}

      {showDeletionModal ? (
        <div className="fixed z-[9999] size-full cursor-auto bg-[#00000030]">
          <OutsideClickHandler onOutsideClick={handleShowDeletionModal}>
            <div
              className={`absolute left-[50%] top-1/4 flex h-[200px] w-[300px] -translate-x-1/2 flex-col items-center justify-center rounded-[5px] bg-white px-[20px] md:w-[500px] md:px-[30px] xl:w-[800px] xl:px-[50px]`}
            >
              <div className="mr-auto flex">
                <p
                  className={`mb-[10px] mr-[15px] text-[12px] font-[600] leading-[135.4%] tracking-[0.01em] text-[#409F85] xl:text-[18px]`}
                >
                  Reason
                </p>
                <p
                  className={`pt-[2px] text-[10px] leading-[135.4%] opacity-60 xl:pt-[4px] xl:text-[14px]`}
                >
                  Minimum 20 characters
                </p>
              </div>
              <textarea
                onChange={handleReasonAreaMessage}
                className={`h-[80px] w-full rounded-[5px] text-[12px] shadow-sm xl:text-[18px]`}
              ></textarea>
              <button
                disabled={deleteReasonError}
                onClick={handleDeleteRecord}
                className={`ml-auto mt-[10px] rounded-[4px] bg-[#409F85] px-[20px] py-[8px] disabled:opacity-30 xl:px-[30px] xl:py-[12px]`}
              >
                <p
                  className={`text-[12px] font-[600] leading-[22px] tracking-[0.04em] text-white xl:text-[18px]`}
                >
                  Submit
                </p>
              </button>
            </div>
          </OutsideClickHandler>
        </div>
      ) : null}
      {!editCreateOption && !userRegistered && !isLoggedIn && (
        <NotLoggedInBanner
          showReviewView={showReviewView}
          selectCreateEditOption={selectCreateEditOption('DISMISS')}
        />
      )}
      <div className="tablet:max-w-[1480px] mx-auto my-2 mb-8 flex w-full max-w-[720px] flex-col items-center justify-center px-4">
        <div className="my-4 mr-auto w-fit">
          <BackButton url={'/'} />
        </div>
        <div className="flex w-full flex-col gap-4 md:flex-row md:justify-between">
          <h3 className="w-fit text-[22px] font-[500]">
            Edit{' '}
            {capitalizeString(
              router?.query?.human_slug?.toString()?.split('_')?.[0]
            )}{' '}
            {capitalizeString(
              router?.query?.human_slug?.toString()?.split('_')?.[1]
            )}
          </h3>
          {continueAndReview ? (
            <div className="mr-auto flex gap-2 md:ml-auto md:mr-0">
              <SecondaryButton
                text="Continue Editing"
                onClick={handleContinueAndReview}
                textClassName="text-sm"
                extraButtonClassName="w-fit ml-auto p-3 rounded-lg bg-transparent shadow-none"
              />
              <PrimaryButton
                text="Confirm"
                spinner={
                  isCreating ? <Spinner color={'text-white'} /> : undefined
                }
                onClick={submitRecord}
                textClassName="text-sm"
                extraButtonClassName="w-fit min-w-[160px] mr-auto md:ml-auto p-3 rounded-lg shadow-none"
              ></PrimaryButton>
            </div>
          ) : (
            <PrimaryButton
              text="Review"
              onClick={handleContinueAndReview}
              disabled={!isConfirmAndContinueButtonEnabled}
              textClassName="text-sm"
              extraButtonClassName="w-fit md:ml-auto p-3 mb-2 rounded-lg shadow-none"
            />
          )}
        </div>
        <div className="tablet:flex-row tablet:items-start mx-4 my-2 flex w-full flex-col items-center justify-center gap-4">
          <div className="w-full  max-w-[720px]">
            <EditHumanForm
              handleFirstName={handleFirstName1}
              handleMiddleName={handleFirstName2}
              handleLastName={handleLastName1}
              handleMaidenName={handleMaidenName}
              handleIsMale={handleIsMale}
              handleIsFemale={handleIsFemale}
              handleBirthCity={handleBirthCity}
              handleBirthRegion={handleBirthRegion}
              handleBirthCountry={handleBirthCountry}
              handleBirthDay={handleBirthDay}
              handleBirthMonth={handleBirthMonth}
              handleBirthYear={handleBirthYear}
              handleBirthMargin={handleBirthMargin}
              handleDeathDay={handleDeathDay}
              handleDeathMonth={handleDeathMonth}
              handleDeathYear={handleDeathYear}
              handleDeathCity={handleDeathCity}
              handleDeathRegion={handleDeathRegion}
              handleIsInferred={handleIsInferred}
              handleDeathCountry={handleDeathCountry}
              onSelectCountry={onSelectCountry}
              countries={countries}
              continueAndReview={continueAndReview}
              allCountries={allCountriesWithRegions}
              isFemale={isFemaleSex}
              firstName={firstName1}
              middleName={firstName2}
              lastName={lastName}
              isMale={isMaleSex}
              maidenName={maidenName}
              countryRef={country}
              birthCity={birthCity}
              birthRegion={birthRegion}
              birthCountry={birthCountry}
              deathCity={deathCity}
              deathRegion={deathRegion}
              deathCountry={deathCountry}
              isFromUpdatePage={true}
              birthDate={{
                ...birthDate,
                approximateYear: isInferred ? '1' : '0',
              }}
              deathDate={deathDate}
            />
          </div>
          <div className="tablet:order-2 desktop:min-w-[700px] -order-1 w-full  max-w-[720px]">
            <CompletationStatus
              firstName={firstName1}
              firstName2={firstName2}
              lastName={lastName}
              isMale={isMaleSex}
              countries={countries}
              maidenName={maidenName}
              isFemale={isFemaleSex}
              birthCity={birthCity}
              birthRegion={birthRegion}
              birthCountry={birthCountry}
              deathCity={deathCity}
              deathRegion={deathRegion}
              deathCountry={deathCountry}
              birthDay={birthDay}
              birthMonth={birthMonth}
              birthYear={birthYear}
              deathDay={deathDay}
              deathMonth={deathMonth}
              deathYear={deathYear}
              country={country}
              continueAndReview={continueAndReview}
              firstCondition={firstCondition}
              listOfRegionsStates={listOfRegionsStates}
              allCountriesWithRegions={allCountriesWithRegions}
              pathsForLocation={pathsForLocation}
              listOfRegionsStatesDeath={listOfRegionsStatesDeath}
              pathsForLocationDeath={pathsForLocationDeath}
              isTypingInFirstName1={isTypingInFirstName1}
              isTypingInFirstName2={isTypingInFirstName2}
              isTypingInLastName={isTypingInLastName}
              isInferred={isInferred}
            />
          </div>
        </div>
        {continueAndReview ? (
          <div className="mr-auto mt-4 flex gap-2">
            <SecondaryButton
              text="Continue Editing"
              onClick={handleContinueAndReview}
              textClassName="text-sm"
              extraButtonClassName="w-fit ml-auto p-3 rounded-lg bg-transparent shadow-none"
            />
            <PrimaryButton
              text="Confirm"
              spinner={
                isCreating ? <Spinner color={'text-white'} /> : undefined
              }
              onClick={submitRecord}
              textClassName="text-sm"
              extraButtonClassName="w-fit min-w-[160px] mr-auto md:ml-auto p-3 rounded-lg shadow-none"
            ></PrimaryButton>
          </div>
        ) : (
          <PrimaryButton
            text="Review"
            onClick={handleContinueAndReview}
            disabled={!isConfirmAndContinueButtonEnabled}
            textClassName="text-sm"
            extraButtonClassName="w-fit mr-auto p-3 rounded-lg shadow-none mt-4"
          />
        )}
      </div>
      <NavFooter className="mt-auto" />
    </div>
  );
}

export const getServerSideProps = async ({
  locale,
  params,
  req,
}: {
  locale: string;
  params: { human_slug: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  req: any;
}) => {
  const countryIso2 =
    params?.human_slug?.split('-')?.[
      params?.human_slug?.split('-')?.length - 1
    ];

  const arrayOfElements = params?.human_slug?.split('-');
  arrayOfElements?.pop();

  const id = arrayOfElements?.join('-');

  if (!id) {
    return {
      props: {
        ...(await serverSideTranslations(locale || 'en', [
          'common',
          'about',
          'partners',
          'navbar',
          'new-homepage',
          'new-navbar',
          'new-footer',
          'human-slug',
        ])),
        countries: [],
        human: [],
        ip: '',
        countryIso3FromProps: '',
        allCountriesWithRegions: [],
      },
    };
  }

  const countries = await searchForAllCountriesStaticProps();

  const allCountriesWithRegions: LocationsTypes[] =
    await getAllCountriesWithRegionOrStateStaticProps();

  const {
    iso_3: countryIso3,
    name: countryName,
    api_public_domain_name: countryDomainName,
  } = countries?.find(
    (country: { iso_2: string }) =>
      country?.iso_2 === countryIso2?.toUpperCase()
  ) as DataWorldChartTypes;

  let ip: string;

  if (req?.headers['x-forwarded-for']) {
    ip = req?.headers['x-forwarded-for']?.split(',')[0];
  } else if (req?.headers['x-real-ip']) {
    ip = req?.connection?.remoteAddress;
  } else {
    ip = req?.connection?.remoteAddress;
  }

  const human_obj = await getHumanById(id, countryIso3, countryDomainName);
  const firstHuman = human_obj?.[0];
  const actualHuman = [
    firstHuman?.name?.first_name1
      ? {
          field_name: 'first_name',
          new_value: firstHuman?.name?.first_name1,
        }
      : null,
    firstHuman?.name?.first_name2
      ? {
          field_name: 'first_name2',
          new_value: firstHuman?.name?.first_name2,
        }
      : null,
    firstHuman?.name?.first_name2
      ? {
          field_name: 'middle_name',
          new_value: firstHuman?.name?.first_name2,
        }
      : null,
    firstHuman?.name?.last_name1
      ? {
          field_name: 'last_name',
          new_value: firstHuman?.name?.last_name1,
        }
      : null,
    firstHuman?.name?.last_name2
      ? {
          field_name: 'last_name2',
          new_value: firstHuman?.name?.last_name2,
        }
      : null,
    firstHuman?.name?.maiden_name
      ? {
          field_name: 'maiden_name',
          new_value: firstHuman?.name?.maiden_name,
        }
      : null,
    firstHuman?.sex
      ? {
          field_name: 'sex',
          new_value: firstHuman?.sex?.includes('female') ? 2 : 1,
        }
      : null,
    countryName ? { field_name: 'country', new_value: countryName } : null,
    firstHuman?.birth?.year !== 0 ||
    firstHuman?.birth?.month !== 0 ||
    firstHuman?.birth?.day !== 0
      ? {
          field_name: 'birth_date',
          new_value: `${String(firstHuman?.birth?.year)?.padStart(
            4,
            '0'
          )}-${String(firstHuman?.birth?.month || '00')?.padStart(
            2,
            '0'
          )}-${String(firstHuman?.birth?.day || '00')?.padStart(2, '0')}`,
        }
      : null,
    firstHuman?.birth?.country
      ? {
          field_name: 'birth_country',
          new_value: firstHuman?.birth?.country,
        }
      : null,

    firstHuman?.birth?.region
      ? {
          field_name: 'birth_region',
          new_value: firstHuman?.birth?.region,
        }
      : null,
    firstHuman?.birth?.city
      ? { field_name: 'birth_city', new_value: firstHuman?.birth?.city }
      : null,
    firstHuman?.death?.year !== 0 ||
    firstHuman?.death?.month !== 0 ||
    firstHuman?.death?.day !== 0
      ? {
          field_name: 'death_date',
          new_value: `${String(firstHuman?.death?.year)?.padStart(
            4,
            '0'
          )}-${String(firstHuman?.death?.month || '00')?.padStart(
            2,
            '0'
          )}-${String(firstHuman?.death?.day || '00')?.padStart(2, '0')}`,
        }
      : null,
    firstHuman?.death?.country
      ? { field_name: 'death_country', new_value: firstHuman?.death?.country }
      : null,
    firstHuman?.death?.region
      ? { field_name: 'death_region', new_value: firstHuman?.death?.region }
      : null,
    firstHuman?.death?.city
      ? { field_name: 'death_city', new_value: firstHuman?.death?.city }
      : null,
    firstHuman?.birth?.is_inferred
      ? { field_name: 'is_inferred', new_value: firstHuman?.birth?.is_inferred }
      : null,
  ]?.filter((human) => human !== null);

  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', [
        'common',
        'about',
        'partners',
        'navbar',
        'new-homepage',
        'new-navbar',
        'new-footer',
        'human-slug',
      ])),
      countries: countries || [],
      human: actualHuman || [],
      ip: ip || '',
      countryIso3FromProps: countryIso3 || '',
      allCountriesWithRegions: allCountriesWithRegions || [],
    },
  };
};
