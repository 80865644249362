import * as React from 'react';

function SuccessIcon() {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 .5a17.5 17.5 0 110 35 17.5 17.5 0 010-35zm-2.18 20.953l-3.888-3.89a1.5 1.5 0 10-2.122 2.122l4.95 4.95a1.496 1.496 0 002.123 0l10.25-10.253a1.501 1.501 0 10-2.12-2.122l-9.193 9.193z"
        fill="#409F85"
      />
    </svg>
  );
}

export default SuccessIcon;
