import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import type { DateErrorOptions } from 'src/types/edit-create-types';
import {
  getMaxDaysInMonth,
  monthDayYearFormatRegex,
  monthYearFormatRegex,
  yearFormatRegex,
} from './format';
import { parseMDY } from './parseMDY';

const checkDateFormatFunctionality = (
  isTyping: boolean,
  valueToRecord: string,
  setErrorDateInputColor: Dispatch<SetStateAction<DateErrorOptions>>,
  setDay: Dispatch<SetStateAction<string>>,
  setMonth: Dispatch<SetStateAction<string>>,
  setYear: Dispatch<SetStateAction<string>>
) => {
  if (!isTyping && valueToRecord?.length === 0) {
    setErrorDateInputColor('create-human-input');
    setDay('');
    setMonth('');
    setYear('');
  }

  if (!isTyping && valueToRecord?.length > 0) {
    const [m, d, y] = parseMDY(valueToRecord);

    const format = monthDayYearFormatRegex.test(valueToRecord)
      ? 'm/d/y'
      : monthYearFormatRegex.test(valueToRecord)
      ? 'm/y'
      : yearFormatRegex.test(valueToRecord)
      ? 'y'
      : 'no-format';

    const currentYear = new Date().getFullYear();

    const maxDaysInMonth = getMaxDaysInMonth(
      parseInt(y as string),
      parseInt(m as string)
    );

    if (format === 'm/d/y') {
      if (parseInt(y as string) > currentYear) {
        setErrorDateInputColor('create-human-input-error-year');
        return;
      }
      if (parseInt(d as string) > maxDaysInMonth) {
        setErrorDateInputColor('create-human-input-error-day-wrong');
        return;
      }
      if (parseInt(m as string) > 12) {
        setErrorDateInputColor('create-human-input-error-month-wrong');
        return;
      }
    }

    if (format === 'm/y') {
      if (parseInt(y as string) > currentYear) {
        setErrorDateInputColor('create-human-input-error-year');
        return;
      }
      if (parseInt(m as string) > 12) {
        setErrorDateInputColor('create-human-input-error-month-wrong');
        return;
      }
    }

    if (format === 'y') {
      if (parseInt(y as string) > currentYear) {
        setErrorDateInputColor('create-human-input-error-year');
        return;
      }
    }

    if (format === 'no-format') {
      setErrorDateInputColor('create-human-input-error');
      return;
    }
    setDay(d || '');
    setMonth(m || '');
    setYear(y || '');
    setErrorDateInputColor('create-human-input');
  }
};

export const useCheckDateFormatFunctionality = (
  isTyping: boolean,
  valueToRecord: string,
  setErrorDateInputColor: Dispatch<SetStateAction<DateErrorOptions>>,
  setDay: Dispatch<SetStateAction<string>>,
  setMonth: Dispatch<SetStateAction<string>>,
  setYear: Dispatch<SetStateAction<string>>
) => {
  const checkDateFormat = useCallback(() => {
    checkDateFormatFunctionality(
      isTyping,
      valueToRecord,
      setErrorDateInputColor,
      setDay,
      setMonth,
      setYear
    );
  }, [
    isTyping,
    valueToRecord,
    setErrorDateInputColor,
    setDay,
    setMonth,
    setYear,
  ]);

  return checkDateFormat;
};
