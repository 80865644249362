import { calls } from 'src/utils/calls';
import { axiosInstance } from 'src/utils/instances';

export const sendMail = (email: string, name: string, text: string) => {
  return axiosInstance(calls.sendMail(email, text, name))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const suggestDeletionEmail = (
  humanId: string,
  reason: string,
  usernameOrIp: string
) => {
  return axiosInstance(
    calls.suggestDeletionEmail(humanId, reason, usernameOrIp)
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const reportRecordEmail = (
  link: string,
  reason: string,
  recipientEmail?: string,
  comment?: string,
  usernameOrIp?: string | null
) => {
  return axiosInstance(
    calls.reportRecordEmail(link, reason, recipientEmail, comment, usernameOrIp)
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const sendEmailWithPNG = (
  email: string,
  html: string,
  name: string,
  png: string,
  figureName: string,
  subject: string
) => {
  return axiosInstance(
    calls.sendEmailWithPNG(email, html, name, png, figureName, subject)
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const unsubscribeEmail = (email: string) => {
  return axiosInstance(calls.unsubscribeEmail(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const sendUnsubscribeEmail = (email: string) => {
  return axiosInstance(calls.sendUnsubscribeEmail(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const searchForUnsubscribedEmails = (email: string) => {
  return axiosInstance(calls.searchForUnsubscribedEmail(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const deleteEmailFromUnsubscribedGroup = (email: string) => {
  return axiosInstance(calls.deleteEmailFromUnsubscribedGroup(email))
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
