export const parseMDY = (s: string) => {
  const [m, d, y] = s.split(/\D/);
  if (m && d && y) {
    return [m, d, y]; // MM/DD/YYYY format
  }
  if (m && d) {
    return [m, undefined, d]; // MM/YYYY format
  }
  if (m && m.length === 4) {
    return [undefined, undefined, m]; // YYYY format
  }
  return [undefined, undefined, undefined]; // invalid format
};
